import { Value as DefaultValue } from '@isomorix/state';
import { isPendingInstance } from '@isomorix/object-types';
import cn from 'classnames';

const rmdExtraProps = [
  'appear',
  'aria-label',
  'aria-labelledby',
  'component',
  'containerClassName',
  'containerStyle',
  'defaultFocus',
  'disableEscapeClose',
  'disableFocusCache',
  'disableFocusContainer',
  'disableFocusOnMount',
  'disableFocusOnMountScroll',
  'disableFocusOnUnmount',
  'disableNestedDialogFixes',
  'disableScrollLock',
  'disableTabFocusWrap',
  'forceContainer',
  'modal',
  'overlay',
  'overlayClassName',
  'overlayHidden',
  'overlayProps',
  'overlayStyle',
  'portal',
  'portalInto',
  'portalIntoId',
  'role',
  'tabIndex',
  'temporary',
  'timeout',
  'type',
  'unmountFocusFallback',
];

const _setType = (type, sv, key, appSizeKey) => {
  const s = sv.getState();
  if (s[key] !== type) {
    sv.set(key, type);
  }
  if (s.appSize && s.appSize[appSizeKey]) {
    sv.set('type', type);
  }
  return sv;
}

const _addExtraSearchParams = (sv, search, arg2) => {
  const s = sv.getState();
  if (!s.extraSearchParams) {
    return search;
  }
  const { extraSearchParams } = s;
  const { store } = sv;
  for(let param in extraSearchParams) {
    if (typeof extraSearchParams[param] === 'function') {
      search[param] = extraSearchParams[param](store, arg2);
    } else if (arg2 === 'close') {
      search[param] = undefined;
    } else {
      search[param] = s[extraSearchParams[param]];
    }
  }
  return search;
}


export class Value extends DefaultValue {


  setImmediateVisibility(bool) {
    const s = this.getState();
    this.set('dialogCn', {
      ...s.dialogCn,
      [s.scaleCn]: bool,
      [s.scaleActiveCn]: bool,
    });
    const style = s.dialogStyle ? { ...s.dialogStyle } : {};
    style.opacity = 1;
    style.transformOrigin = `${s.offsetX || 0}px ${s.offsetY || 0}px`;
    this.set('dialogStyle', style);
    this.set('isTransitioning', false);
    this.set('updateTransitionTimeout', 0);
    return this;
  }

  setVisibility(bool, event) {
    if (typeof bool !== 'boolean') {
      bool = !this.state.visibility;
    } else if (bool === this.state.visibility) {
      return this;
    }
    this.set('isTransitioning', true);
    let s = this.getState();
    if (event) {
      this.set('offsetX', event.clientX || event.x);
      this.set('offsetY', event.clientY || event.y);
    }
    this.set('visibility', bool);
    if (bool) {
      this.set('viewCount', this.get('viewCount') + 1);
      if (isSSR(this, s, event)) {
        return this.setImmediateVisibility(this, true);
      }
      this.set('dialogCn', {
        ...s.dialogCn,
        [s.scaleCn]: s.type === 'full-page' ? true : false,
        [s.scaleActiveCn]: false
      });
      const style = s.dialogStyle ? { ...s.dialogStyle } : {};
      if (s.type === 'full-page') {
        s = this.getState();
        style.opacity = 1;
        style.transformOrigin = `${s.offsetX || 0}px ${s.offsetY || 0}px`;
      } else {
        style.opacity = 0;
        style.transformOrigin = undefined;
      }
      this.set('dialogStyle', style);
      this.set('updateTransitionTimeout', 19);
    } else {
      this.set('dialogCn', {
        ...s.dialogCn,
        [s.scaleCn]: true,
        [s.scaleActiveCn]: false
      });
      this.set('updateTransitionTimeout', s.transitionTime);
    }
    return this;
  }

  openDialog(event) {
    const { store } = this;
    const loc = this.get('location');
    const search = loc && this.getOpenDialogSearchParams(event);
    this.setVisibility(true, event);
    this.commitAndNotify();
    if (search) {
      loc.pushSearch(search);
    }
    return store.value;
  }

  getOpenDialogSearchParams(event) {
    const s = this.getState();
    if (
      !s.visibilitySearchParam
      || (s.location && s.location.searchParams[s.visibilitySearchParam])
    ) {
      return null;
    }
    const search = {
      [s.visibilitySearchParam]: true,
    };
    if (event && s.offsetXSearchParam) {
      search[s.offsetXSearchParam] = event.clientX || event.x;
      search[s.offsetYSearchParam] = event.clientY || event.y;
    }
    return _addExtraSearchParams(this, search, 'open');
  }

  getCloseDialogSearchParams() {
    const s = this.getState();
    if (
      !s.visibilitySearchParam
      || (s.location && !s.location.searchParams[s.visibilitySearchParam])
    ) {
      return null;
    }
    const search = {
      [s.visibilitySearchParam]: undefined,
    };
    if (s.offsetXSearchParam) {
      search[s.offsetXSearchParam] = undefined;
      search[s.offsetYSearchParam] = undefined;
    }
    return _addExtraSearchParams(this, search, 'close');
  }

  closeDialog(replaceSearch, to) {
    const { store } = this;
    this.get('onRequestClose')(replaceSearch, to);
    return store.value;
  }

  updateTransition(commit) {
    const s = this.getState();
    const cls = s.dialogCn;
    if (s.visibility) {
      if (!cls[s.scaleCn]) {
        this.set('dialogCn', { ...cls, [s.scaleCn]: true });
        const style = s.dialogStyle ? { ...s.dialogStyle } : {};
        style.opacity = 0;
        if (typeof s.offsetX === 'number' && s.dialogRef.current) {
          const rect = s.dialogRef.current.getBoundingClientRect();
          style.transformOrigin = `${s.offsetX - rect.left}px ${s.offsetY - rect.top}px`;
        } else {
          style.transformOrigin = `${s.offsetX || 0}px ${s.offsetY || 0}px`;
        }
        this.set('dialogStyle', style);
        /*
         * transitionTimeout is used by the component to
         * trigger calling this method (useEffect).
         * Therefore, it must a unique number, which
         * is why this is 20 and below is 19.
         */
        this.set('updateTransitionTimeout', 20);
      } else if (s.dialogStyle && s.dialogStyle.opacity === 0) {
        this.set('dialogStyle', { ...s.dialogStyle, opacity: 1 });
        this.set('updateTransitionTimeout', 19);
      } else if (!cls[s.scaleActiveCn]) {
        this.set('dialogCn', { ...cls, [s.scaleActiveCn]: true });
        this.set('updateTransitionTimeout', s.transitionTime);
      } else if (s.isTransitioning) {
        this.set('isTransitioning', false);
        this.set('updateTransitionTimeout', 0);
      }
    } else if (cls[s.scaleActiveCn]) {
      this.set('dialogCn', { ...cls, [s.scaleActiveCn]: false });
      this.set('updateTransitionTimeout', s.transitionTime);
    } else if (s.isTransitioning) {
      this.set('isTransitioning', false);
      this.set('updateTransitionTimeout', 0);
      this.set('offsetX', 0);
      this.set('offsetY', 0);
    }
    return commit
      ? this.commitAndNotify()
      : this;
  }

  setLocation(loc) {
    const s = this.getState();
    if (s.location === loc || (!s.location && !loc)) return this;
    if (s.locationSub) {
      s.locationSub.unsubscribe();
      this.set('locationSub', null);
    }
    this.set('location', loc || null);
    if (!loc) return this;
    if (isPendingInstance(loc) && process.env.BROWSER) {
      const main = loc.getMainInstance();
      const { store } = this;
      loc.subscribe({
        complete: () => {
          if (store.value && store.get('location') === loc) {
            store.value.set('location', main);
            store.value.commitAndNotify();
          }
        }
      })
    }
    if (!s.visibilitySearchParam) {
      return this;
    }
    const { visibilitySearchParam, offsetXSearchParam, offsetYSearchParam } = s;
    const { store } = this;
    const fn = loc => {
      const { searchParams } = loc;
      let sv = store.value;
      if (!sv) {
        sub.unsubscribe();
        return;
      }
      if (!searchParams || !searchParams[visibilitySearchParam]) {
        if (sv.get('visibility')) {
          sv.closeDialog();
        }
      } else if (!sv.get('visibility')) {
        if (offsetXSearchParam) {
          if (typeof searchParams[offsetXSearchParam] === 'number') {
            sv.set('offsetX', searchParams[offsetXSearchParam]);
            sv.set('offsetY', searchParams[offsetYSearchParam]);
          } else {
            sv.set('offsetX', 0);
            sv.set('offsetY', 0);
          }
        }
        sv.setVisibility(true);
      }
      const onChange = sv.get('onLocationChange');
      if (onChange) {
        onChange(store, loc);
      }
      if ((sv = store.value) && sv.pState) {
        sv.commitAndNotify();
      }
    };

    const sub = loc.subscribeAndPersist(fn);
    this.set('locationSub', sub);
    fn(loc);
    return store.value;
  }

  getRmdProps(is2D) {
    const s = this.getState();
    const props = {
      id: `${s.dialogId}__${is2D ? '2D' : '3D'}`,
      ref: s.dialogRef,
      visible: s.visibility || s.isTransitioning,
      disableTransition: true,
      onRequestClose: s.onRequestClose,
      style: s.dialogStyle,
      className: cn(s.dialogCn),
    };
    for(let key of rmdExtraProps) {
      props[key] = s[key];
    }
    return props;
  }

  setDesktopType(type) {
    return _setType(type, this, 'desktopType', 'isDesktop');
  }

  setTabletType(type) {
    return _setType(type, this, 'tabletType', 'isTablet');
  }

  setPhoneType(type) {
    return _setType(type, this, 'phoneType', 'isPhone');
  }

  updateType(appSize, commit) {
    const s = this.getState();
    let type;
    if (appSize.isPhone) {
      type = s.phoneType;
    } else if (appSize.isTablet) {
      type = s.tabletType;
    } else {
      type = s.desktopType;
    }
    this.set('appSize', appSize);
    if (s.type !== type) {
      this.set('type', type);
    }
    if (commit && this.pState) {
      return this.commitAndNotify();
    }
    return this;
  }

  // get isFullPage() {
  //   return this.get('type') === 'full-page';
  // }
  //
  // get isPhone() {
  //   const size = this.get('appSize');
  //   return !size ? true : size.isPhone || false;
  // }
  //
  // get isDesktop() {
  //   const size = this.get('appSize');
  //   return size && size.isDesktop || false;
  // }
  //
  // get isTablet() {
  //
  // }

  complete() {
    const s = this.getState();
    if (s.locationSub) {
      s.locationSub.unsubscribe();
      s.locationSub = undefined;
    }
    s.location = undefined;
    super.complete();
  }
}

let isSSR = () => false;
if (process.env.BROWSER) {
  const done = isSSR;
  isSSR = (sv, state, event) => {
    if (
      event
      || !state.location
      || !state.visibilitySearchParam
      /*
       * If a prev location key exists
       * in the record's state, it would
       * mean this visibility was triggered
       * on the page, rather than during hydration.
       */
      || state.location.getPrev('key')
    ) {
      isSSR = done;
      return false;
    }
    isSSR = () => true;
    setTimeout(() => (isSSR = done), 100);
    return true;
  }
  Value.prototype.isSSR = function(event) {
    return isSSR(this, this.getState(), event);
  }
} else {
  isSSR = function() { return true; }
  Value.prototype.isSSR = isSSR;
}
