import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { CORE_LOGIC } from '../../constants';

export const Docs = React.memo(function Docs(props) {
  const { logic } = props;
  useRecordSubscription(logic);
  const { match } = logic;
  if (!match) return null;
  const { localProps: { docsStore } } = logic;
  const Container = docsStore.get('Container');
  return <Container key='docsContainer' store={docsStore} />;
});

Docs.displayName = CORE_LOGIC.DOCS;
