/*
 * Route slugs only need to be unique on a
 * per-plugin level, not globally. So no
 * need to prefix the slug like with
 * Permissions or Logic
 */
export const ROUTE_SLUGS = {
  ROUTER: 'app',
  API_DETAILS: 'api_details',
  DOCS: 'docs',
  GRAPHQL_PLAYGROUND: 'graphql_playground',
  GRAPHQL_VOYAGER: 'graphql_voyager',
  USER: 'user',


  OUR_TEAM: 'our_team',
  ABOUT_US: 'about_us',
  EVENTS: 'events',
  SUPPORT: 'support',
  BLOG: 'blog',

  START: 'start',
  GROUP: 'group',
  AMF_LETTER_3_DAY_SPECIAL: 'amf_letter_3_day_special',

  TRAINING: 'training',
  TRAINING_AMF: 'training_amf',
  TRAINING_SOCIAL_RETAIL_BLUEPRINT: 'training_social_retail_blueprint',
  TRAINING_REELS_FOR_REAL: 'training_reels_for_real',

  APP_BUILDER: 'app_builder',

  AI: 'ai',
  ADMIN: 'admin'
};
