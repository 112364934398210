import { of } from "rxjs";


function prepareRecord(modelBuilder) {
  const recBuilder = modelBuilder.getRecordBuilder();
  const plugin = modelBuilder.parentStore.mainInstance;
  const methods = recBuilder.Class.prototype;
  let AiSessionModel;
  const _getAiSessionModel = dispatchId => {
    if (AiSessionModel || (AiSessionModel = plugin.switchTo('AiSession')) || !dispatchId) {
      return AiSessionModel;
    }
    if ((AiSessionModel = plugin.getBestInstance(dispatchId).switchTo('AiSession'))) {
      AiSessionModel = AiSessionModel.getMainInstance();
    }
    return AiSessionModel;
  }

  function getActiveAiSession(dispatchId) {
    const model = _getAiSessionModel(dispatchId);
    return model
      ? model.getActiveAiSession(this)
      : null;
  }
  methods.getActiveAiSession = getActiveAiSession;

  function ensureActiveAiSession(dispatchId) {
    const model = _getAiSessionModel(dispatchId);
    return model
      ? model.ensureActiveAiSession(this, dispatchId)
      : of(null);
  }
  methods.ensureActiveAiSession = ensureActiveAiSession;
}

export function prepareUserModel(modelBuilder) {
  prepareRecord(modelBuilder);
}
