import React from 'react';
import { useRecordSubscription } from "@isomorix/react";
import { PermissionDenied } from "./PermissionDenied";
import { TextContainer, Typography } from "@react-md/typography";
import { snakeCaseToWords } from "@isomorix/utils";
import { IMG_BASE_PATH, ROUTE_SLUGS } from "../constants";

export function createComingSoonComponent(displayName, Content) {
  const Component = React.memo(function({ logic }) {
    return <ComingSoon logic={logic} Content={Content} />
  });
  Component.displayName = displayName;
  return Component;
}
export const ComingSoon = React.memo(function ComingSoon(
  {
    logic,
    Content = DefaultContent
  }
) {
  useRecordSubscription(logic);
  const { match } = logic;
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  } else if (!match) {
    return null;
  }
  const { route } = logic;
  const { meta, slug } = route;
  if (slug === ROUTE_SLUGS.BLOG) {
    return (<Content logic={logic} match={match} />);
  }
  let title = meta && meta.title;
  if (!title) {
    title = snakeCaseToWords(logic.route.slug, true);
  }
  let heroContent;
  if (slug === ROUTE_SLUGS.ABOUT_US) {
    heroContent = (
      <div className={'coming-soon-route__hero--content about-us-route'}>
        <Typography type={'headline-1'}>
          What Is AttractionMarketing.com?
        </Typography>
        <div className={'about-us-route--image-container'} >
          <img
            src={`${IMG_BASE_PATH}/about-us-play-button.png`}
            alt={'Play About Us Video'}
          />
        </div>
      </div>
    )
  } else {
    heroContent = (
      <div className={'coming-soon-route__hero--content'}>
        <Typography type={'headline-1'}>
          {  title }
        </Typography>
      </div>
    )
  }
  return (
    <div className={'coming-soon-route'}>
      <div
        className={'coming-soon-route__hero'}
        style={{
          backgroundImage: `url(${IMG_BASE_PATH}/${slug === ROUTE_SLUGS.SUPPORT ? ROUTE_SLUGS.EVENTS : slug}.jpg)`
        }}
      >
        { heroContent }
      </div>
      <div className={'coming-soon-route__content'}>
        <Content logic={logic} />
      </div>
    </div>

  )
});

const DefaultContent = () => (
  <TextContainer>
    <Typography type={'headline-2'}>
      Coming Soon...
    </Typography>
  </TextContainer>
)
