import { Docs } from './Docs';
import { docsRouteLogicMgr } from './logic';

export function docsRoute(pluginBuilder, coreLogicRoutes) {
  coreLogicRoutes.addRoute(
    Docs,
    './routes/docs',
    docsRouteLogicMgr
  );
}
