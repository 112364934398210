import { routerLogicMgr as logicMgr } from "./logicMgr";
import { MUTATION } from "@isomorix/core-actions";
import { AI_CHAT_DIALOG_NAME } from "../../constants";
import { aiChatChunkLoader } from "../../aiChat";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { asyncLogicResp } from "@isomorix/store";

const builder = logicMgr.getBuilder(MUTATION, './mutation');

function loadAiChat(action, observer) {
  const logic = action.meta.instance;
  const { match } = logic;
  if (match && match.searchParams[AI_CHAT_DIALOG_NAME]) {
    let mod;
    return aiChatChunkLoader(action).pipe(
      mergeMap(module => {
        mod = module;
        return module
          ? module.ensureAiModels(action)
          : of(action);
      }),
      mergeMap(() => mod
        ? mod.addToLogic(action, builder)
        : of(action)
      )
    )
  } else {
    return asyncLogicResp(null, action, observer);
  }
}

/*
 * After trxOptimisticComplete so on the client
 * the progress bar will show while we load everything.
 */
builder.useRelative('trxOptimisticComplete', 50)
  .setName('ensureResources');
if (process.env.NODE_ENV !== 'production') {
  builder.setDescription('Ensures AI chat is available to render.');
}
builder.add(loadAiChat, true);
