import { useAddMessage } from "@react-md/alert";
import { useCallback } from "react";

export function useNoLink(message) {
  const addMessage = useAddMessage();
  const onClick = useCallback((e) => {
    if (e) e.preventDefault();
    if (!message) {
      message = 'Page pending publishing';
    } else if (typeof message === 'object') {
      addMessage(message);
      return;
    }
    addMessage({
      messageId: message,
      children: message,
      messagePriority: "replace"
    });
  }, [ message, addMessage ]);
  return onClick;
}
