// If changing, also update in src/_variables.scss -> $rmd-app-bar-height
export const APP_BAR_HEIGHT = 48;

export const AMF_LETTER_SCROLL_ELEM_ID = 'amf-letter-scroll-elem';
export const AMF_CHECKOUT_SCROLL_ELEM_ID = 'amf-checkout-scroll-elem';
/*
 * If renaming ./styles.scss #rmd-layout-main-content-scrollable,
 * change this constant to match it.
 */
export const MAIN_ELEM_SCROLL_ID = 'rmd-layout-main-content-scrollable';
