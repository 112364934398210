import { AppBuilderRoute } from './AppBuilder';
import { appBuilderLogicMgr } from './logic';

export function appBuilderRoute(pluginBuilder, coreLogicRoutes) {
  coreLogicRoutes.addRoute(
    AppBuilderRoute,
    './appBuilder',
    appBuilderLogicMgr
  );
}

export { appBuilderLogicMgr }
