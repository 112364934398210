import { appBuilderLogicMgr as logicMgr } from './logicMgr';
import {
  CORE_LOGIC_ACTIONS as ACTIONS,
} from '@isomorix/core-logic';
import { loadable } from '@isomorix/store';
import { CORE_LOGIC_TYPES } from '@isomorix/core-config';
import { filter, mergeMap, take } from 'rxjs/operators';


const builder = logicMgr.getBuilder(ACTIONS.INIT, './init');

if (process.env.BROWSER) {

  function initExtraLocalProps(action, module) {
    let logic = action.meta.instance;
    const { localProps } = logic;
    if (!module) return action;
    let router = logic.getParentByType(CORE_LOGIC_TYPES.ROUTER);
    const routerLocalProps = router.localProps;
    if (routerLocalProps.viewMode === '2D') {
      /**
       * @type {module:model.Mutation}
       * @ignore
       */
      const m = action.meta.store.parentInstance.mutation(action.meta.dispatchId);
      router = m.getMutableRecord(router);
      const routerLocalProps = router.getMutableLocalProps();
      routerLocalProps.appBuilderModule = module;
      router.localProps = routerLocalProps;
      localProps.appModule = module;
      m.execute();
    } else {
      /*
       * The DndProvider is implemented by the LayoutContainer so that
       * it is not affected by transitions. For example, if switching
       * 3D graphs, the app will unmount & mount, and the DndProvider
       * will freak out because there will be "2 HTML5Backends".
       *
       * The solution is to have the LayoutContainer provide it, but
       * that means that when it renders it for the first time
       * it will unmount the current render since now it will have
       * a new parent. Therefore, we need to transition out of
       * 3D world, make the switch, then transition back in.
       */
      router = router.getMainInstance();
      logic = action.meta.mainInstance;
      const LogicModel = action.meta.store.parentInstance.getMainInstance();
      action.meta.mainActionMeta.subscribe({
        complete: () => {
          const {
            clearLayout3DStoreOnClose,
            viewModeTransitionTime,
          } = router.localProps;
          router.setLocalProps({
            render2D: true,
            render3D: true,
            viewMode: '2D',
            clearLayout3DStoreOnClose: false,
            viewModeTransitionTime: 300
          }).pipe(
            mergeMap(router => router.subscribe()),
            filter(({ localProps: p }) => p.viewMode === '2D' && !p.render3D),
            take(1),
            mergeMap((router) => {
              /**
               * @type {module:model.Mutation}
               * @ignore
               */
              const m = LogicModel.mutation();
              m.getMutableRecord(router).setLocalProps({
                appBuilderModule: module
              });
              m.getMutableRecord(logic).setLocalProps({ appModule: module });
              m.execute();
              return m.ofComplete(true);
            }),
            mergeMap(() => router.setLocalProps({
              render2D: true,
              render3D: true,
              viewMode: '3D',
            })),
            mergeMap(router => router.subscribe()),
            filter(({ localProps: p }) => p.viewMode === '3D' && !p.render2D),
            take(1),
            mergeMap(router => router.setLocalProps({
              clearLayout3DStoreOnClose,
              viewModeTransitionTime
            }))
          ).subscribe();
        }
      })
    }
    return action;
  }

  builder.useRelative('initLocalProps', 10)
    .setName('initExtraLocalProps')
    .setPure(true)
    // .add(initExtraLocalProps, true);
    .add(loadable(
      // () => import('../../../../../../../appify/branches/isomx-ui-demo/packages/react-tester/src/embed'),
      // () => import('../../../../../../app-builder/packages/index'),
      () => import('@isomorix/app-builder-v0'),
      initExtraLocalProps,
      builder.getCurrent()
    ), true);
}




