import React from 'react';
import { useRecordSubscription } from "@isomorix/react";
import { PermissionDenied } from "../PermissionDenied";
import { CORE_LOGIC } from "../../constants";
import { LinearProgress } from "@react-md/progress";

export const Admin = React.memo(function Admin(
  {
    logic
  }
) {
  const changeKey = useRecordSubscription(logic);
  if (!logic.match) return null;
  if (logic.permDenied) {
    return (<PermissionDenied logic={logic} />);
  }
  const { Admin } = logic.localProps;
  return Admin
    ? (<Admin changeKey={changeKey} logic={logic} />)
    : (<LinearProgress className={'rmd-progress--secondary'} />);
});

Admin.displayName = CORE_LOGIC.ADMIN;
