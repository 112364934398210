import React from 'react';
import { createComingSoonComponent } from "../ComingSoon";
import { CORE_LOGIC } from "../../constants";
import { TextContainer } from "@react-md/typography";


function Content() {
  return (
    <TextContainer>
      <p>
        AttractionMarketing.com is the premier online community for today’s Digital Network Marketer. It’s a place where you can learn how to market your business like a professional, connect with top leaders growing their businesses online and develop the pro skills you need to grow your business on social media and other online channels. With over 100,000 customers and students in 100 countries around the globe, we’ve become one of the world’s most trusted voices sharing the best of what’s working today in attraction marketing.
      </p>
    </TextContainer>
  )
}

export const AboutUs = createComingSoonComponent(CORE_LOGIC.ABOUT_US, Content);
