import { appBuilderLogicMgr as logicMgr } from './logicMgr';
import { MUTATION } from '@isomorix/core-actions';
import {
  useCss,
  useRouterContext,
  getThemeType,
  setThemeType,
} from '@isomorix/react-router';
import { useLayoutContext } from '@isomorix/react-md-layout';
import { TextContainer, Typography } from '@react-md/typography';
import { Button } from '@react-md/button';
import { FontIcon, TextIconSpacing } from '@react-md/icon';
import { filter, map, mergeMap, startWith, take } from "rxjs/operators";

const builder = logicMgr.getBuilder(MUTATION, './mutation');

if (process.env.BROWSER) {
  function ensureAppClient(action) {
    const sv = action.meta.store.value;
    if (!sv.get('match')) return action;
    let localProps = sv.get('localProps');
    // let router = sv.store.instance.getParentByType(CORE_LOGIC_TYPES.ROUTER);
    // const routerLocal = router.localProps;
    // if (routerLocal.navVisibility && routerLocal.hideNav) {
    //   router = action.payload.mutation.getMutableRecord(router);
    //   routerLocal.hideNav();
    //   router.setLocalProps({ navVisibility: false });
    // }
    if (!localProps.appMgr && localProps.appModule) {
      const logic = action.meta.instance;
      localProps = logic.getMutableLocalProps();
      const appMgr = localProps.appModule.createAppMgr();
      const main = action.meta.store.mainStore.instance;
      appMgr.getRootThemeType = () => {
        return getThemeType(main.getSession());
      }
      appMgr.setRootThemeType = (themeType) => {
        return setThemeType(main.getSession(), themeType);
      }
      appMgr.locSearchKey = 'abloc';
      appMgr.useRouterContext = useRouterContext;
      appMgr.useLayoutContext = useLayoutContext;
      appMgr.useCss = useCss;
      appMgr.TextContainer = TextContainer;
      appMgr.Typography = Typography;
      appMgr.Button = Button;
      appMgr.FontIcon = FontIcon;
      appMgr.TextIconSpacing = TextIconSpacing;
      localProps.appMgr = appMgr;
      logic.localProps = localProps;
      const filterIdentity = filter(val => !!val);
      const mergeMapIdentity = mergeMap(ModelOrRec => ModelOrRec
        .subscribeAndPersist()
        .pipe(startWith(ModelOrRec))
      );
      const sub = appMgr.ofChanges().pipe(
        map(appMgr => appMgr.store && appMgr.store.get('Plugin')),
        filterIdentity,
        take(1),
        mergeMapIdentity,
        map(Plugin => {
          const state = Plugin.getState();
          let rec;
          for(let key in state) {
            rec = state[key];
            if (rec.slug === 'react_md' && rec.pluginInstance) {
              return rec.pluginInstance;
            }
          }
          return undefined;
        }),
        filterIdentity,
        take(1),
        mergeMapIdentity,
        map(rmdPlugin => rmdPlugin.getState().ToolbarTitle),
        filterIdentity,
        mergeMapIdentity,
        map(ttModel => {
          const s = ttModel.getState();
          let titleRec, cRender;
          for(let key in s) {
            titleRec = s[key];
            if (
              (cRender = titleRec.componentRender)
              && cRender.name === 'EditorToolbarTitle'
            ) {
              return titleRec;
            }
          }
        }),
        filterIdentity,
        take(1)
      ).subscribe(titleRec => {
        const titleStore = appMgr
          .store
          .storeConfig
          .networkStores
          .get(titleRec._storeId);
        const reducer = (sv) => {
          let title = sv.get('title');
          if (title && title.indexOf('isomx') > -1) {
            sv.state.title = title.replace('isomx - ', '');
          }
        }
        titleStore.addPendingReducers([ reducer ]);
        reducer(titleStore.value);
      });
      action.meta.store.mainStore.subscribe({
        complete: () => {
          sub.unsubscribe();
        }
      });
    }
    return action;
  }

  builder.useRelative('trxOptimisticExecute', 10)
    .setName('ensureAppClient')
    .setPure(true)
    .add(ensureAppClient, true)
}
