import { useEffect, useState } from "react";
import { getRouteLogic } from "../helpers";

export function useRouteLogic(slug, routerLogic) {
  const [ logic, setLogic ] = useState(() => getRouteLogic(routerLogic.routeLogic, slug));
  useEffect(() => {
    if (logic && logic.route.slug === slug) return;
    let routesSub = routerLogic.routeLogicSubscribe(sv => {
      const logic = getRouteLogic(sv.getState(), slug);
      if (!logic) return;
      setLogic(logic);
      routesSub.unsubscribe();
      routesSub = undefined;
    });
    return () => routesSub && routesSub.unsubscribe();
  }, [ slug, logic ]);
  return logic;
}
