import React, { useCallback, useMemo } from 'react';
import { getThemeType } from '@isomorix/react-router';
import { ReloadAppButton } from './ReloadAppButton';
import { ThemeTypeAppBarAction } from '../../ThemeTypeAppBarAction';
import { UserAppBarAction } from '../../UserAppBarAction';
import { ViewModeIconButton } from '../../ViewModeIconButton';
import {
  ROUTE_SLUGS
} from '../../constants';
// import { Support } from "./Support";
// import { Avatar } from "@react-md/avatar";
import { AppBarAction } from "@react-md/app-bar";
import { SVGIcon } from "@react-md/icon";
import { SiteSearch } from "./SiteSearch";
import { AiChatAppBarAction } from "../../AiChatAppBarAction";


const isBrowser = process.env.BROWSER;

export function useAppBarActions(
  {
    logic,
    session,
    is2D,
    DocsSearchButton,
    isDocsRoute
  }
) {
  const themeType = getThemeType(session);
  const overviewOnClick = useCallback(() => {
    session.setState({
      meta: { ...session.meta, auth: 'overview' }
    })
  }, [ session ]);
  return useMemo(() => {
    const siteSearch = (
      <SiteSearch key={'siteSearch'} routerLogic={logic} isDocsRoute={isDocsRoute} />
    );
    let search;
    if (DocsSearchButton && (isBrowser || isDocsRoute)) {
      search = DocsSearchButton && (
        <DocsSearchButton
          key={'docsSearchButton'}
          first={!!isDocsRoute}
          routerLogic={logic}
          isDocsRoute={isDocsRoute}
          docsRouteSlug={ROUTE_SLUGS.DOCS}
        />
      );
    }
    const reloadApp = is2D && ReloadAppButton && (
      <ReloadAppButton
        is2D={is2D}
        key={'reloadAppButton'}
        // first={!!!(search && (isDocsRoute || true))}
        first={false}
        appBarAction={true}
      />
    );
    return [
      siteSearch,
      search,
      reloadApp,
      (<ViewModeIconButton
          key={'changeViewMode'}
          logic={logic}
          menuBaseId={`toggle_view_mode_${is2D ? '2D' : '3D'}`}
          appBarAction={true}
          // first={!!!(reloadApp || (search && isDocsRoute))}
          first={false}
        />
      ),
      (
        <AppBarAction
          key={'overviewVideo'}
          buttonType={'button'}
          aria-label={'Access Overview Video'}
          onClick={overviewOnClick}
        >
          <SVGIcon>
            <path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12zm-5-6l-7 4V7z"/>
          </SVGIcon>
        </AppBarAction>
      ),
      // (<Support key={'support'} session={session} />),
      (<ThemeTypeAppBarAction
        key={'toggleTheme'}
        // first={!!!(reloadApp || (search && isDocsRoute))}
      />),
      (<AiChatAppBarAction key={'aiChatAppBarAction'} />),
      // (
      //   <AppBarAction
      //     key={'locale'}
      //     buttonType={'button'}
      //     aria-label={'change locales'}
      //     onClick={() => {
      //       addMessage({
      //         children: `Requires Translation API keys`
      //       })
      //     }}
      //   >
      //     <TextIconSpacing
      //       icon={(
      //         <img
      //           width={'21px'}
      //           height={'15px'}
      //           src={`${IMG_BASE_PATH}/country_flags/SVG/US.svg`}
      //           alt={'English'}
      //         />
      //       )}
      //     >
      //       EN
      //     </TextIconSpacing>
      //   </AppBarAction>
      // ),
      (<UserAppBarAction
          key={'userMenu'}
          logic={logic}
          last={true}
        />
      )];
  }, [
    logic,
    session,
    is2D,
    themeType,
    DocsSearchButton,
    isDocsRoute
  ]);
}
