import React from 'react';
import { CORE_LOGIC } from "../../constants";
import { useRecordSubscription } from "@isomorix/react";
import { PermissionDenied } from "../PermissionDenied";
import { Start as StartRender } from "../../start";

export const Start = React.memo(function Start(
  {
    logic
  }
) {
  useRecordSubscription(logic);
  const { match } = logic;
  if (!match) return null;
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  }
  return <StartRender session={logic.getSession()} />
});

Start.displayName = CORE_LOGIC.START;
