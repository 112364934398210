import { from, of } from "rxjs";
import { mergeMapFetchResp } from "./helpers";
import { catchError, map, mergeMap } from "rxjs/operators";

const catch500 = catchError(() => of(null));
const cache = new Map();
if (process.env.NODE_ENV !== 'production') {
  cache.set(
    '97.101.201.176',
    {
      "is_valid": true,
      "country": "United States",
      "country_code": "US",
      "region_code": "FL",
      "region": "Florida",
      "city": "Cocoa Beach",
      "zip": "32931",
      "lat": 28.3327,
      "lon": -80.6147,
      "timezone": "America/New_York",
      "isp": "Charter Communications",
      "address": "97.101.201.176"
    }
  );
}

const CURRENCY_TIMEOUT = process.env.NODE_ENV === 'production'
  ? (1000 * 60 * 20)
  : 0;

export class ApiNinjasClient {
  constructor(baseUrl, apiKey) {
    this.url = baseUrl;
    this.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Api-Key': apiKey
    };
    this.cache = cache;
  }

  static createConnMethod(host, apiKey) {
    let ins;
    return () => {
      if (!ins) {
        ins = new this(host, apiKey);
      }
      return ins;
    }
  }

  getFromCache(url, timeout) {
    let entry = this.cache.get(url);
    if (entry) {
      if ((Date.now() - entry.ts) < timeout) {
        return entry.resp;
      }
      this.cache.delete(url);
    }
    return false;
  }

  setCache(url, resp) {
    if (resp !== null) {
      this.cache.set(url, { ts: Date.now(), resp });
    }
    return resp;
  }

  execute(url) {
    return from(fetch(url, {
      method: 'GET',
      headers: this.headers,
      credentials: 'omit'
    })).pipe(mergeMapFetchResp(), catch500);
  }

  convertCurrency(from, to, amount) {
    if (from === to) return of(amount);
    const url = `${this.url}/convertcurrency?have=${from}&want=${to}&amount=${amount}`;
    const resp = this.getFromCache(url, CURRENCY_TIMEOUT);
    if (resp !== false) return of(resp);
    return this.execute(url).pipe(
      map(value => this.setCache(url, value.new_amount))
    );
  }

  /**
   *
   * @param {?string} [ip]
   * @param {module:core/session.Record} [session]
   * @param {module:core/session.Record} [mainSess]
   * @returns {import("rxjs").Observable.<Object>}
   * @ignore
   */
  ipLookup(ip, session, mainSess) {
    if (
      session
      && ((!ip && process.env.BROWSER) || (ip && ip.startsWith('192.')))
    ) {
      const main = mainSess
        ? mainSess
        : session
          ? session.getMainInstance()
          : undefined;
      return from(fetch('https://api.ipify.org?format=json'))
        .pipe(
          mergeMapFetchResp(),
          mergeMap(resp => this.ipLookup(resp.ip, session, main)),
          catchError(e => {
            console.log(`Got ipify error = `, e.message);
            return of(null);
          })
      )
    } else if (!ip && (!session || !(ip = session.ip))) {
      return of(null);
    }
    const url = `${this.url}/iplookup?address=${ip}`;
    const resp = this.getFromCache(url, 1000 * 60 * 60 * 24);
    if (resp !== false) {
      if (session) {
        updateSess(resp, session, mainSess);
      }
      return of(resp);
    }
    const $ = this.execute(url);
    if (!session) {
      return $.pipe(map(resp => this.setCache(url, resp)));
    }
    if (!mainSess) {
      mainSess = session.getMainInstance();
    }
    return $.pipe(
      map(resp => {
        updateSess(resp, session, mainSess);
        return this.setCache(url, resp);
      })
    )
  }
}

const updateSess = (resp, session, mainSess) => {
  const sess = session.__ID ? session : mainSess;
  if (!sess) return;
  if (resp) {
    sess.setState({
      ip: resp.address,
      countryCode: resp.country_code,
      regionCode: resp.region_code,
      city: resp.city,
      zip: resp.zip,
      lat: resp.lat,
      lon: resp.lon,
      timezone: resp.timezone,
      ipIsValid: resp.is_valid
    });
  } else if (session.ip) {
    session.setState({ ipIsValid: false });
  }
}
