export const AI_AVATAR = {
  FERNY_CEBALLOS: 'Ferny_Ceballos',
  RAMIRO_CEBALLOS: 'Ramiro_Ceballos',
  TIM_ERWAY: 'Tim_Erway',
  MATT_CRYSTAL: 'Matt_Crystal',
  KATE_MCSHEA: 'Kate_McShea',
  ANDREW_DRAUGHON: 'Andrew_Draughon',
  VITALY_GRINBLAT: 'Vitaly_Grinblat',
  MAURA: 'Maura',
}

export const AI_DEFAULT_AVATAR = 'Maura';

export const AI_DATA_MODEL = {
  PROSPECTING: 'prospecting',
  TRAFFIC: 'traffic',
  SOCIAL_MEDIA: 'social_media',
  AMF: 'amf',
}

export const AI_CHAT_DIALOG_NAME = 'aic';
export const AI_CHAT_FULLSCREEN_SEARCH_PARAM = 'aicfs';

export const AI_INTERACTION_STATUS = {
  PENDING: 'pending',
  SUBMITTING: 'submitting',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
}

export const AI_OUTPUT = {
  TEXT: 'text',
  HTML: 'html',
  JSON: 'json',
  JAVASCRIPT: 'javascript',
  CSV: 'csv'
}
