import React from 'react';
import { loadable, useRouterContext } from '@isomorix/react-router';
import { LinearProgress } from '@react-md/progress';
import { useRecordSubscription } from '@isomorix/react';
import { PermissionDenied } from '../../PermissionDenied';
import { CORE_LOGIC, PATHS } from '../../../constants';

const Voyager = loadable(() => /* webpackChunkName: "graphqlVoyager" */ import('./Voyager'), {
  resolveComponent: ({ Voyager }) => Voyager,
  ssr: false,
  fallback: <LinearProgress className={'rmd-progress--secondary'} />
});

export const GraphqlVoyager = React.memo(function GraphqlVoyager(
  {
    logic,
  }
) {
  useRecordSubscription(logic);
  const { session } = useRouterContext();
  const { match } = logic;
  if (!match) {
    return null;
  } else if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  } else {
    return (
      <div style={{
        height: 'calc(100vh - var(--rmd-app-bar-height))'
      }}>
      <Voyager
        session={session}
        logic={logic}
        workerURI={PATHS.VOYAGER_WORKER}
      />
      </div>
    );
  }
});

GraphqlVoyager.displayName = CORE_LOGIC.GRAPHQL_VOYAGER;
