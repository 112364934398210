import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MUTATION } from '@isomorix/core-actions';
import { loadable } from '@isomorix/store';
import { blogRouteLogicMgr as logicMgr } from './logicMgr';

const builder = logicMgr.getBuilder(MUTATION, './mutation');

const chunk = loadable(() => import('../blogChunk'));
function ensureResources(action) {
  const logic = action.meta.instance;
  if (logic.match) {
    action.payload.mutation.addToFollowupQueries(forkJoin([
      chunk(action),
      logic.getPlugin().loadMeilisearchConnections(action)
    ]).pipe(
      mergeMap(([{ addToLogic }]) => addToLogic(action, builder, ensureResources))
    ));
  }
  return action;
}

builder
  .useRelative('trxOptimisticExecute', 100)
  .setName('ensureResources');
if (process.env.NODE_ENV !== 'production') {
  builder.setDescription(`Ensures blog content is available to render the "blog" route`)
}
builder
  .setPure(false)
  .add(ensureResources, true);
