import React from 'react';
import { FontIcon } from '@react-md/icon';
import { Button } from '@react-md/button';
import { AppBarAction } from '@react-md/app-bar';

let ReloadAppButton;
if (process.env.NODE_ENV !== 'production') {
  const onClick = (e) => {
    e.preventDefault();
    if (
      typeof window !== 'undefined'
      && window.reloadApp
    ) {
      window.reloadApp();
    }
  }
  ReloadAppButton = React.memo(function ReloadAppButton(
    {
      appBarAction,
      first,
      is2D,
    }
  ) {
    if (!is2D) return null;
    if (appBarAction) {
      return (
        <AppBarAction
          key={'reloadApp'}
          onClick={onClick}
          first={first}
          aria-label={'Reload app'}
        >
          <FontIcon>sync</FontIcon>
        </AppBarAction>
      )
    } else {
      return (
        <Button
          key={'reloadApp'}
          theme={'primary'}
          themeType={'contained'}
          floating={'bottom-right'}
          onClick={onClick}
        >
          <FontIcon>sync</FontIcon>
        </Button>
      )
    }
  })
}

export { ReloadAppButton }
