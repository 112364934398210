import React, { useMemo } from 'react';
import {Grid, GridCell} from "@react-md/utils";
import { useNoLink } from "../hooks";
import { Link } from "@react-md/link";


const linkText = [
  'Terms and Conditions',
  'Higher Standards',
  'Refunds',
  'Privacy Policy'
];
const linkStyle = {
  color: 'rgb(137, 151, 169)',
  textDecoration: 'none',
}

export const Footer = React.memo(function Footer(
  {

  }
) {
  const noLink = useNoLink('Legal pages pending publishing');
  const footerLinks = useMemo(() => {
    const resp = [];
    let txt;
    for(let i = 0; i < linkText.length; i++) {
      if ( i > 0) {
        resp.push(' | ');
      }
      txt = linkText[i];
      resp.push(
        <a
          key={txt}
          alt={txt}
          href={'/'}
          onClick={noLink}
          style={linkStyle}
        >
          { txt }
        </a>
      )
    }
    return resp;
  }, [ noLink ]);
  return (
    <div className={'app-footer'}>
      <Grid
        className={'app-footer--grid'}
        gutter={'32px'}
        padding={'48px 48px 48px 48px'}
      >
        <GridCell colSpan={4}>
          <p className={'cell-title'}>
            AttractionMarketing.com
          </p>
          <p className={'cell-content'}>
            AttractionMarketing.com is a global community of over 100,000 active small business entrepreneurs in more than 100 countries who share a common dream: do what you love, earn unlimited income, and work from anywhere in the world by building a “lifestyle business”.
          </p>
        </GridCell>
        <GridCell colSpan={4}>
          <p className={'cell-title'}>Contacts</p>
          <p className={'cell-content'}>
            AttractionMarketing.com<br/>
            274 E Eau Gallie Blvd Ste 375<br/>
            Indian Harbour Beach, Fl 32937
          </p>
          <p className={'cell-content'}>
            Call Us: (877) 401-3422<br/>
            9AM to 5PM M-F (CST)
          </p>
        </GridCell>
        <GridCell colSpan={4}>
          <p className={'cell-title'}>Connect With Us</p>
          <svg className="social-icon" viewBox="0 0 448 512">
            <path
              d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"/>
          </svg>
          <svg className="social-icon" viewBox="0 0 448 512">
            <path
              d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"/>
          </svg>
          <svg className="social-icon" viewBox="0 0 448 512">
            <path
              d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
          </svg>
        </GridCell>
      </Grid>
      <Grid
        className={'app-footer--grid app-footer--top-border'}
        gutter={'16px'}
        padding={'0px 48px 48px 48px'}
      >
        <GridCell colSpan={12} className={'app-footer--divider'}></GridCell>
        <GridCell colSpan={6}>
          <p className={'copyright-text'}>
            © Copyright 2014 - 2022 AttractionMarketing.com, All Rights Reserved
          </p>
        </GridCell>
        <GridCell colSpan={6}>
          <p className={'bottom-links'}>
            { footerLinks }
            {/*Terms and Conditions | Higher Standards | Refunds | Privacy Policy*/}
          </p>
        </GridCell>
      </Grid>
    </div>
  )
});
