import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Main } from "./Main";
import { WelcomeCards } from "./WelcomeCards";
// import { WhatIsAM } from "./WhatIsAM";
// import { JoinCommunity } from "./JoinCommunity";
// import { RecentArticles } from "./RecentArticles";
import { loadable, useLocationSearch } from "@isomorix/react-router";
import { MAIN_ELEM_SCROLL_ID } from "../../constants";
// import { useAppSize } from "@react-md/utils";

const JoinCommunityForm = loadable(() => import('../../forms/joinCommunity'), {
  resolveComponent: mod => mod.JoinCommunity,
  ssr: false
});

const Part2Components = loadable(() => import('./HomePart2'), {
  ssr: false
})

let hasRenderedPart2;

export function Home(
  {
    // logic,
    session,
    setElevation
  }
) {
  const location = useLocationSearch('joinCommunity');
  const { searchParams } = location;
  const [ Form, setForm ] = useState(null);
  useEffect(() => {
    if (searchParams.joinCommunity) {
      setForm(JoinCommunityForm);
    }
  }, [ searchParams ]);
  const ref = useRef();
  const [ Part2, setPart2 ] = useState(hasRenderedPart2 ? Part2Components : null);
  useEffect(() => {
    if (!ref.current || hasRenderedPart2) return;
    const elem = document.getElementById(MAIN_ELEM_SCROLL_ID);
    if (elem && elem.scrollTop > 100) {
      hasRenderedPart2 = true;
      setPart2(Part2Components);
      return;
    }
    const obs = new IntersectionObserver(( [ { isIntersecting } ]) => {
      if (isIntersecting) {
        hasRenderedPart2 = true;
        setPart2(Part2Components);
      }
    }, {
      threshold: 0.75
    });
    obs.observe(ref.current);
    return () => obs.disconnect();
  })
  const openJoinForm = useCallback(e => {
    location.pushSearch({
      joinCommunity: true,
      joinCommunityX: e.clientX || e.x,
      joinCommunityY: e.clientY || e.y,
      joinCommunityStep: 1
    });
  }, [ location ]);
  return (
    <>
      <div className={'home'}>
        <Main session={session} setElevation={setElevation} />
        <WelcomeCards
          session={session}
          openJoinForm={openJoinForm}
          intersectionRef={ref}
        />
        {
          Part2 && <Part2 session={session} openJoinForm={openJoinForm} />
        }
        {/*<WhatIsAM />*/}
        {/*<JoinCommunity openJoinForm={openJoinForm} />*/}
        {/*<RecentArticles session={session} />*/}
      </div>
      { Form && <Form session={session} />}
    </>
  )
}

// export function Home(
//   {
//     // logic,
//     session
//   }
// ) {
//   let title;
//   const { userRole } = session;
//   if (userRole) {
//     title = `Welcome, ${userRole.user.firstName}`;
//   } else {
//     title = `Welcome To Attraction Marketing`;
//   }
//   return (
//     <TextContainer>
//       <Typography type={'headline-1'}>
//         { title }
//       </Typography>
//       <Typography type="body-1">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum laborum nisi odio optio placeat porro possimus reprehenderit sit sunt voluptatibus. Accusamus accusantium dolore et exercitationem ipsum laudantium nemo reiciendis ut?
//       </Typography>
//       <Typography type="body-1">
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aspernatur deleniti itaque minima nulla perspiciatis sint ullam, voluptates. Ab animi architecto assumenda atque aut culpa delectus dignissimos earum eligendi esse exercitationem explicabo fugit harum inventore laudantium magni molestias, mollitia nam necessitatibus nemo reiciendis repellendus repudiandae rerum sed vero voluptates voluptatum. Ab amet, assumenda atque debitis delectus dolorum earum esse expedita hic in itaque iusto laborum magnam maiores minus, nam omnis optio perferendis possimus quae quidem sapiente sed suscipit tempore temporibus vel voluptatibus. Ad alias aliquam autem consectetur consequuntur cumque numquam quidem tenetur voluptatibus.
//       </Typography>
//       <Typography type="body-1">
//         Aliquam ex facere maxime molestias mollitia neque nesciunt perspiciatis quam recusandae veniam? Amet architecto atque aut dolore eligendi facilis iure, modi nam odio placeat quaerat quas ratione repellat suscipit tempore ullam unde voluptas. Dignissimos doloribus ipsa laboriosam laudantium numquam omnis pariatur quidem reprehenderit? A ad alias, amet architecto atque commodi consectetur consequatur corporis deleniti deserunt dicta dolorum, ea eveniet, excepturi harum ipsum laborum laudantium magnam mollitia officiis perferendis possimus quae qui quibusdam repudiandae similique tempora veritatis voluptate voluptates voluptatibus?
//       </Typography>
//     </TextContainer>
//   )
// }
