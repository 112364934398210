import React, { useEffect, useRef, useState } from 'react';
import { useRouteLogic } from "../../hooks";
import {
  BLOG_PAGE_SEARCH_PARAM,
  BLOG_QUERY_SEARCH_PARAM,
  MAIN_ELEM_SCROLL_ID,
  ROUTE_SLUGS
} from "../../constants";
import { TextField, TextFieldAddon } from "@react-md/form";
import { SVGIcon } from "@react-md/icon";
import cn from 'classnames';
import { isBlogPath } from "../../helpers";
import { LOCATION_CHANGE_TYPE } from "@isomorix/core-config";


const inputCn = 'site-search-app-bar-input';
const inputContainerCn = 'site-search-app-bar-input-container';

export const SiteSearch = React.memo(function SiteSearch(
  {
    routerLogic: logic,
    isDocsRoute
  }
) {
  const blogLogic = useRouteLogic(ROUTE_SLUGS.BLOG, logic);
  const inputRef = useRef(null);
  let localProps, searchStateStore;
  if (blogLogic) {
    ({ localProps } = blogLogic.getState());
    searchStateStore = localProps && localProps.searchStateStore;
  }
  const [, forceRender ] = useState(0);
  useEffect(() => {
    if (!blogLogic || searchStateStore) return;
    let sub = blogLogic.subscribeAndPersist(logic => {
      const { localProps } = logic;
      if (localProps && localProps.searchStateStore) {
        sub.unsubscribe();
        sub = undefined;
        forceRender(prev => ++prev);
      }
    })
  }, [ blogLogic, searchStateStore ]);
  const [ state, setState ] = useState(() => {
    const value = localProps && localProps.getSearchState
      ? localProps.getSearchState().query || ''
      : '';
    return {
      value,
      visibility: !!value || !!logic.localProps.siteSearchVisibility
    };
  });
  const cache = useRef({ isFirst: true, state });
  useEffect(() => {
    const handler = e => {
      if (!e.ctrlKey) return;
      if (e.code === 'KeyK') {
        e.preventDefault();
        e.stopPropagation();
        const shiftKey = e.shiftKey;
        setState(state => {
          if (state.visibility && shiftKey) {
            setTimeout(
              () => inputRef.current && inputRef.current.blur(),
              5
            );
            return { value: '', visibility: false };
          } else {
            setTimeout(
              () => inputRef.current && inputRef.current.focus(),
              5
            );
            return { value: '', visibility: true };
          }
        })
      } else if (e.code === 'KeyB' && window.loadAppBuilder) {
        e.preventDefault();
        e.stopPropagation();
        window.loadAppBuilder();
      }
    }
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [ logic, setState, inputRef ]);
  useEffect(() => {
    const sub = logic.getLocation().subscribe(loc => {
      if (loc.changeType === LOCATION_CHANGE_TYPE.POP) {
        const value = loc.searchParams[BLOG_QUERY_SEARCH_PARAM] || '';
        setState(prev => prev.value === value && prev.visibility === !!value
          ? prev
          : { value, visibility: !!value }
        );
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      }
    });
    return () => sub.unsubscribe();
  }, [ logic, inputRef ]);
  useEffect(() => {
    if (cache.current.isFirst) {
      cache.current.isFirst = false;
      return;
    }
    const timeout = setTimeout(() => {
      let ctrl;
      const routerLocalProps = logic.localProps;
      if (state.visibility !== routerLocalProps.siteSearchVisibility) {
        const m = logic.getModel().mutation();
        ctrl = m.controller;
        m.update(logic, {
          localProps: {
            ...routerLocalProps,
            siteSearchVisibility: state.visibility
          }
        });
      }
      const sv = searchStateStore && searchStateStore.value;
      if (!state.value) {
        if (
          sv
          && (
            sv.get('query')
            || (
              !blogLogic.props.infiniteScroll
              && sv.get('page') !== 1
                // Guard against opening & then closing without
                // doing anything
              && cache.current.state.query
            )
          )
        ) {
          sv.set('query', '');
          const isExact = isBlogPath(logic.getLocation().pathname, true);
          if (isExact) {
            sv.set('page', 1);
          }
          sv.commitAndNotify();
          if (isExact) {
            const elem = document.getElementById(MAIN_ELEM_SCROLL_ID);
            if (elem) {
              elem.scrollTop = 0;
            }
          }
        }
      } else {
        let loc = logic.getLocation();
        let isPop = loc.changeType === LOCATION_CHANGE_TYPE.POP;
        if (isPop) {
          const key = loc.key;
          if (cache.current.popKey === key) {
            isPop = false;
          } else {
            cache.current.popKey = key;
          }
        }
        const isBlogExactPath = isBlogPath(loc.pathname, true);
        if (sv && sv.get('query') !== state.value) {
          sv.set('query', state.value);
          sv.set('page', 1);
          sv.commitAndNotify();
          if (!isPop && isBlogExactPath) {
            setTimeout(() => {
              const elem = document.getElementById(MAIN_ELEM_SCROLL_ID);
              if (elem) {
                elem.scrollTop = 0;
              }
            }, 200);
          }
        }
        if (!isBlogExactPath && !isPop) {
          const m = ctrl
            ? ctrl.switchTo(loc.__typename)
            : logic.getModel().switchTo(loc.__typename).mutation();
          ctrl = m.controller;
          loc = m.getMutableRecord(loc);
          const infiniteScroll = blogLogic && blogLogic.props.infiniteScroll;
          loc.push({
            pathname: `/${ROUTE_SLUGS.BLOG}`,
            searchParams: {
              [BLOG_PAGE_SEARCH_PARAM]: infiniteScroll
                ? undefined
                : sv && sv.get('page') || undefined,
              [BLOG_QUERY_SEARCH_PARAM]: state.value
            }
          });
        }
      }
      if (ctrl) {
        ctrl.execute();
      }
      cache.current.state = state;
    }, 100);
    return () => clearTimeout(timeout);
  }, [ state, setState, searchStateStore, blogLogic, logic, cache ]);
  if (isDocsRoute) return null;
  const { visibility } = state;
  const leftAddonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (state.visibility) {
      setState({ value: '', visibility: false });
    } else {
      setState({ value: '', visibility: true });
      inputRef.current.focus();
    }
  };
  let leftAddon;
  if (visibility) {
    leftAddon = (
      <SVGIcon className={'left-addon'} onClick={leftAddonClick}>
        <g>
          <rect fill="none" height="24" width="24"/>
        </g>
        <g>
          <g>
            <path d="M15.5,14h-0.79l-0.28-0.27C15.41,12.59,16,11.11,16,9.5C16,5.91,13.09,3,9.5,3C6.08,3,3.28,5.64,3.03,9h2.02 C5.3,6.75,7.18,5,9.5,5C11.99,5,14,7.01,14,9.5S11.99,14,9.5,14c-0.17,0-0.33-0.03-0.5-0.05v2.02C9.17,15.99,9.33,16,9.5,16 c1.61,0,3.09-0.59,4.23-1.57L14,14.71v0.79l5,4.99L20.49,19L15.5,14z"/>
            <polygon points="6.47,10.82 4,13.29 1.53,10.82 0.82,11.53 3.29,14 0.82,16.47 1.53,17.18 4,14.71 6.47,17.18 7.18,16.47 4.71,14 7.18,11.53"/>
          </g>
        </g>
      </SVGIcon>
    )
  } else {
    leftAddon = (
      <SVGIcon className={'left-addon'} onClick={leftAddonClick}>
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
      </SVGIcon>
    )
  }
  return (
    <TextField
      theme={'outline'}
      ref={inputRef}
      placeholder={'Find anything...'}
      onChange={() => {
        setState({ visibility: true, value: inputRef.current.value });
      }}
      onBlur={() => {
        if (state.visibility && !state.value) {
          setState({ visibility: false, value: '' });
        }
      }}
      onClick={visibility ? undefined : leftAddonClick }
      inputClassName={cn(inputCn, visibility && `${inputCn}--visible`)}
      className={cn(inputContainerCn, visibility && `${inputContainerCn}--visible`)}
      rightChildren={state.value && visibility && (
        <TextFieldAddon presentational={false}>
          <SVGIcon
            className={'right-addon'}
            onClick={() => {
              setState({ visibility: true, value: '' });
              inputRef.current.focus();
            }}
          >
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 12.59L17.59 17 14 13.41 10.41 17 9 15.59 12.59 12 9 8.41 10.41 7 14 10.59 17.59 7 19 8.41 15.41 12 19 15.59z"/>
          </SVGIcon>
        </TextFieldAddon>
      )}
      isRightAddon={false}
      leftChildren={(
        // Must force unmount, otherwise the onClick() won't be
        // updated, and neither will focus
        // (it stays highlighted on mobile when closing otherwise)
        <TextFieldAddon key={visibility ? 1 : 0} presentational={false}>
          { leftAddon }
        </TextFieldAddon>
      )}
      isLeftAddon={false}
      value={state.value}
    />
  );
});
