import React, { useEffect, useState } from 'react';
import { createScaleDialogStore, ScaleDialog } from "../../components";
import { useLocationDialog, useTrainingAccess } from "../../hooks";
import { loadable } from "@isomorix/react-router";
import { ROUTE_SLUGS } from "../../constants";
import { LinearProgress } from "@react-md/progress";

const Content = loadable(() => /* webpackChunkName: "amfLetterSpecialContent" */ import('./AmfLetterSpecialContent'), {
  ssr: true,
  fallback: <LinearProgress />
})

const createStore = (session) => {
  const store = createScaleDialogStore({
    state: {
      session,
      name: 'amfLetterSpecial',
      dialogId: 'amfLetterSpecial',
      dialogStyle: {
        '--countdown-timer-color': 'var(--rmd-app-bar-on-primary)',
        '--countdown-timer-background-color': 'rgba(0, 0, 0, 0)'
      },
      extraSearchParams: {
        checkout: (store, action) => action === 'close'
          ? undefined
          : false,
        checkoutStep: '', // will result in `undefined`, removing it from params
        checkoutProduct: '',
        amfCheckoutLogin: '',
        amfCheckoutLoginType: '',
        amfCheckoutLoginStep: '',
      },
      Content,
    }
  }, { fullPage: true, baseSearchParam: 'amfLetterSpecial' });
  session.setDialog('amfLetterSpecial', store);
  return store;
}

export const AmfLetterSpecial = React.memo(function AmfLetter(
  {
    session,
  }
) {
  const hasAccess = useTrainingAccess(ROUTE_SLUGS.TRAINING_AMF);
  const [ store, setStore ] = useState(null);
  if (hasAccess && !store) return null;
  return (
    <AmfLetterSpecialRender
      session={session}
      setStore={setStore}
    />
  )
});

const AmfLetterSpecialRender = ({ session, setStore }) => {
  const store = useLocationDialog(session, 'amfLetterSpecial', createStore);
  useEffect(() => {
    if (!store) {
      setStore(null);
      return;
    }
    setStore(store);
    const sub = store.subscribe({
      complete: () => {
        setStore(null);
      }
    });
    return () => sub.unsubscribe();
  }, [ store, setStore, session ]);
  if (!store) return null;
  return (
    <ScaleDialog
      className={'amf-letter-special-dialog'}
      store={store}
  />);
}


