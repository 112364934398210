import React, {
  useEffect,
  useMemo,
  useRef, useState
} from 'react';
import {
  useRouterContext,
  getBestTitle,
  useCss, getThemeType, loadable
} from '@isomorix/react-router';
import { Layout as ReactMdLayout, useLayoutConfig } from '@react-md/layout';
import { LinearProgress } from '@react-md/progress';
import { useCrossFadeTransition } from '@react-md/transition';
import {
  LayoutContext,
  useLayoutContext,
  useLayoutNavigation,
} from '@isomorix/react-md-layout';
import { Home } from './Home';
import { useAppBarActions } from './appBarActions';
import {
  APP_BAR_HEIGHT,
  MAIN_ELEM_SCROLL_ID as SCROLL_ELEM_ID
} from '../constants';
import { isAppBuilderPath, isBlogPath, isDocsPath } from '../helpers';
import { Footer } from "./Footer";
import { AmfLetterSpecial } from "../dialogs";
import { LOCATION_CHANGE_TYPE } from "@isomorix/core-config";
import { AppBarLogo } from "../components";
import { useAppSize } from "@react-md/utils";
import { SVGIcon } from "@react-md/icon";
import { ViewModeFABButton } from "../ViewModeIconButton";
import { useVFSubscriptionValue } from "@isomorix/react";

const Access = loadable(() => import('./access'), {
  fallback: <LinearProgress />
});

const navHeaderPropsLight = {
  title: "Navigation",
  theme: 'clear'
};
const navHeaderPropsDark = {
  title: "Navigation",
  theme: 'primary'
};

const useDocsCss = process.env.BROWSER
  ? (templateCss) => useCss(templateCss)
  : (templateCss, isDocs) => isDocs && useCss(templateCss);
// export const Layout = React.memo(function Layout(props) {
//   const { logic } = props;
//   const [ token, setToken ] = useState(() => logic.getSession()
//     .meta.accessToken
//   );
//   const [ Cookie, setCookie ] = useState(() => {
//
//   })
//   return null;
// });

export const Layout = React.memo(function Layout(props) {
  const { is2D, logic } = props;
  const {
    /*
     * The renderer is a utility that will automatically
     * render child logic. It's not necessary to use it,
     * but it handles rendering them in the proper
     * order and other things. See its documentation
     * for details (@isomx/react).
     */
    renderer,
    location,
    session
  } = useRouterContext();
  const prevPathname = useRef('');
  const rootValue = useLayoutContext();
  const { elementProps, transitionTo } = useCrossFadeTransition();
  const appSize = useAppSize();
  const { localProps, match } = logic;
  const { DocsSearchButton } = localProps;
  useVFSubscriptionValue(logic, 'children', true);
  const missingRoutesCount = localProps.missingChildRoutes
    ? localProps.missingChildRoutes.length
    : 0;
  // const [ authorized, setAuthorized ] = useState(() => session.meta.authorized);
  // useEffect(() => {
  //   let current = session.meta.authorized;
  //   const sub = session.subscribe(sess => {
  //     const val = sess.meta.authorized;
  //     if (val !== current) {
  //       current = val;
  //       setAuthorized(val);
  //     }
  //   });
  //   return () => sub.unsubscribe();
  // })
  const { pathname } = location;
  const themeType = getThemeType(session);
  const isDocs = isDocsPath(pathname);
  // const isBlog = isDocs ? false : isBlogPath(pathname);
  const [ elevated, setElevation ] = useState(!match.isExact);
  useDocsCss(localProps.docsTemplateCss, isDocs);
  const { navVisibility } = localProps;
  const appBarTheme = themeType === 'dark' ? 'primary' : 'clear';
  const contextValue = useMemo(() => ({
    ...rootValue,
    setElevation,
    is2D,
    scrollWidth: rootValue.width,
    scrollHeight: rootValue.height - APP_BAR_HEIGHT,
    isRoot: false,
    appBarTheme,
    navVisibility: navVisibility || false
  }), [ rootValue, is2D, appBarTheme, navVisibility ]);
  useEffect(() => {
    if (missingRoutesCount) return;
    if (
      prevPathname.current &&
      pathname !== prevPathname.current &&
      (!isDocsPath(prevPathname.current) || !isDocsPath(pathname)) &&
      (!isBlogPath(prevPathname.current) || !isBlogPath(pathname))
    ) {
      if (location.changeType !== LOCATION_CHANGE_TYPE.POP) {
        const elem = document.getElementById(SCROLL_ELEM_ID);
        if (elem) {
          elem.scrollTop = 0;
        }
      }
      transitionTo('enter');
      setTimeout(() => location.registerScrollElem(`#${SCROLL_ELEM_ID}`), 20);
    } else {
      setTimeout(() => location.registerScrollElem(`#${SCROLL_ELEM_ID}`, 10));
    }
    prevPathname.current = pathname;
  }, [ location.key, location, transitionTo, is2D, missingRoutesCount ]);
  const treeProps = useLayoutNavigation(logic, session);
  treeProps.navClassName = 'layout-navigation-tree-nav styled-scrollbars';
  const appBarChildren = useAppBarActions({
    logic,
    session,
    is2D,
    DocsSearchButton,
    isDocsRoute: isDocs
  });
  let title;
  if (match.isExact) {
    const routeMeta = logic.route.meta;
    title = routeMeta && routeMeta.title
      ? routeMeta.title
      : 'Home';
  } else {
    title = getBestTitle(location);
    const idx = title.indexOf(' - Posts');
    if (idx > 0) {
      title = title.substring(0, idx);
    }
    // if (title.indexOf(' - Posts') > -1) {
    //   title = title.replace(' - Posts', '');
    // }
  }
  let docsPortal = null;
  if (localProps.docsStore && !localProps.docsStore.closed) {
    const Portal = localProps.docsStore.get('Portal');
    docsPortal = <Portal store={localProps.docsStore} />;
  }
  const isAppBuilder = isAppBuilderPath(pathname);
  const layoutType = isAppBuilder
    ? 'toggleable-mini'
    : appSize.isDesktop
      ? 'toggleable'
      : 'temporary';
  if (session.meta.auth !== 'app') {
    return (
      <LayoutContext.Provider value={contextValue}>
        <Access logic={logic} />
      </LayoutContext.Provider>
    )
  }
  // useEffect(() => {
  //   // if (!match.isExact) {
  //   //   setAppBarTheme('primary');
  //   //   return;
  //   // }
  //   // const sub = fromEvent(document.getElementById(SCROLL_ELEM_ID), 'scroll')
  //   //   .subscribe(e => {
  //   //     if (e.target.scrollTop > 24) {
  //   //       setAppBarTheme('primary');
  //   //     } else {
  //   //       setAppBarTheme('clear');
  //   //     }
  //   //   });
  //   // return () => sub.unsubscribe();
  // }, [ match.isExact, setAppBarTheme ]);
  return (
    <LayoutContext.Provider value={contextValue}>
      <ReactMdLayout
        id={is2D ? 'layout' : 'layout3D' }
        title={<AppBarLogo title={title} clickable />}
        // desktopLayout={'toggleable'}
        navAfterAppBar={true}
        navHeaderProps={themeType === 'light'
          ? navHeaderPropsLight
          : navHeaderPropsDark
        }
        navToggleProps={{
          children: (
            <SVGIcon>
              <path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            </SVGIcon>
          )
        }}
        treeProps={treeProps}
        tabletLayout={layoutType}
        desktopLayout={layoutType}
        largeDesktopLayout={layoutType}
        phoneLayout={layoutType}
        mainProps={{
          ...elementProps,
          headerOffset: true,
          mini: isAppBuilder
        }}
        appBarProps={{
          fixedElevation: !match.isExact || elevated || true,
          theme: appBarTheme,
          // theme: appBarTheme,
          // theme: match.isExact && !elevated ? 'clear' : 'primary',
          children: appBarChildren
        }}
      >
        <div id={SCROLL_ELEM_ID}>
          <ManageNavVisibility
            logic={logic}
            isAppBuilder={isAppBuilder}
          />
          { !missingRoutesCount
              ? undefined
              : (<LinearProgress
                    key={'loading'}
                    className={'rmd-progress--secondary'}
                />)
          }
          { match.isExact && (
              <Home
                logic={logic}
                session={session}
                setElevation={setElevation}
              />
          )}
          { renderer.many(logic.children) }
          { !isDocs
            && !missingRoutesCount
            && !isAppBuilder
            && pathname.indexOf('api_details') < 0
            && pathname.indexOf('admin') < 0
            && pathname.indexOf('ai') < 0
            && <Footer />
          }
          {
            is2D && isAppBuilder &&  (
              <ViewModeFABButton logic={logic} />
            )
          }
        </div>
      </ReactMdLayout>
      <AmfLetterSpecial session={session} />
      {/*<AmfCheckout session={session} />*/}
      { docsPortal }
    </LayoutContext.Provider>
  )
});

const ManageNavVisibility = React.memo(function ManageNavVisibility(
  {
    logic,
    isAppBuilder
  }) {
  const { showNav, visible, layout, hideNav } = useLayoutConfig();
  const isFirst = useRef(true);
  const appBuilderCache = useRef(isAppBuilder);
  useEffect(() => {
    if (isAppBuilder) {
      appBuilderCache.current = true;
      return;
    }
    if (isFirst.current) {
      isFirst.current = false;
      if (
        layout === 'temporary'
        || window.navigator.userAgent.indexOf('Chrome-Lighthouse') > -1
      ) {
        return;
      }
      const { localProps } = logic;
      if (localProps.navVisibility) {
        setTimeout(() => showNav(), 5);
        logic.setLocalProps({ hideNav });
      } else if (typeof logic.localProps.navVisibility !== 'boolean') {
        logic.setLocalProps({ navVisibility: false, hideNav: null });
      }
      return;
      // logic.setLocalProps({
      //   navVisibility: visible,
      //   hideNav,
      // });
    }
    const { localProps } = logic;
    if (appBuilderCache.current) {
      if (localProps.navVisibility) {
        setTimeout(() => showNav(), 5);
        if (localProps.hideNav !== hideNav) {
          logic.setLocalProps({ hideNav });
        }
      }
      appBuilderCache.current = false;
      return;
    }
    if (layout !== 'temporary') {
      if (localProps.navVisibility !== visible) {
        logic.setLocalProps({ navVisibility: visible, hideNav });
      }
    } else if (typeof localProps.navVisibility === 'boolean') {
      logic.setLocalProps({ navVisibility: null, hideNav: null });
    }
  }, [ logic, visible, isAppBuilder, hideNav ]);
  return null;
})

// function Logo(props) {
//   console.log('props = ', props);
//   return (
//     <TextIconSpacing
//       icon={<img className={'app-bar-logo'} src={'/static/img/fav.png'} />}
//     >
//       AttractionMarketing.com
//     </TextIconSpacing>
//   )
// }
