import { apiDetailsRoute } from './api_details';
import { UserRoute } from './user';
import { docsRoute } from './docs';
import { OurTeam } from "./ourTeam";
import { AboutUs } from "./aboutUs";
import { Events } from "./events";
import { Support } from "./support";
import {
  blogRoute
  // Blog
} from "./blog";
import { Start } from "./start";
import { Group } from "./group";
import { AmfLetterSpecial } from "./amfLetter";
import { trainingRoute } from "./training";
import { appBuilderRoute } from "./appBuilder";
import { adminRoute } from "./admin";
// import { nextTick$ } from "../helpers";
// import { mergeMap } from "rxjs/operators";

export function addRoutes(pluginBuilder, coreLogicRoutes) {
  apiDetailsRoute(pluginBuilder, coreLogicRoutes);
  docsRoute(pluginBuilder, coreLogicRoutes);
  coreLogicRoutes.addRoute(UserRoute, './user');
  appBuilderRoute(pluginBuilder, coreLogicRoutes);
  blogRoute(pluginBuilder, coreLogicRoutes);
  adminRoute(pluginBuilder, coreLogicRoutes);

  trainingRoute(pluginBuilder, coreLogicRoutes);
  coreLogicRoutes
    .addRoute(OurTeam, './ourTeam')
    .addRoute(AboutUs, './aboutUs')
    .addRoute(Events, './events')
    .addRoute(Support, './support')
    // .addRoute(Blog, './blog')
    .addRoute(Start, './start')
    .addRoute(Group, './group')
    .addRoute(AmfLetterSpecial, './amfLetter');

  // return nextTick$.pipe(
  //   mergeMap(() => {
  //     apiDetailsRoute(pluginBuilder, coreLogicRoutes);
  //     docsRoute(pluginBuilder, coreLogicRoutes);
  //     coreLogicRoutes.addRoute(UserRoute, './user');
  //     return nextTick$;
  //
  //   }),
  //   mergeMap(() => {
  //     trainingRoute(pluginBuilder, coreLogicRoutes);
  //     return nextTick$;
  //   }),
  //   mergeMap(() => {
  //     coreLogicRoutes
  //       .addRoute(OurTeam, './ourTeam')
  //       .addRoute(AboutUs, './aboutUs')
  //       .addRoute(Events, './events')
  //       .addRoute(Support, './support');
  //     return nextTick$;
  //   }),
  //   mergeMap(() => {
  //     coreLogicRoutes.addRoute(Blog, './blog')
  //       .addRoute(Start, './start')
  //       .addRoute(Group, './group')
  //       .addRoute(AmfLetterSpecial, './amfLetter');
  //     return nextTick$;
  //   })
  // )

}
