import { docsRouteLogicMgr as logicMgr } from './logicMgr';
import { MUTATION } from '@isomorix/core-actions';
import { asyncLogicResp } from '@isomorix/store';

const builder = logicMgr.getBuilder(MUTATION, './mutation');

function updateDocsMatch(action, observer) {
  const { meta: { store } } = action;
  let localProps = store.get('localProps');
  if (
    (localProps || (localProps = store.value.getPrev('localProps')))
    && localProps.mutationLogic
  ) {
    return localProps.mutationLogic(action, observer);
  }
  return asyncLogicResp(null, action, observer);
}

builder.useRelative('trxOptimisticExecute', 10)
  .setName('updateDocsMatch')
  .setPure(false)
  .add(updateDocsMatch, true);
