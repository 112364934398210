export const getRouteLogic = (routeLogic, slug) => {
  if (!routeLogic) return undefined;
  let route;
  for(let key in routeLogic) {
    if ((route = routeLogic[key].route) && route.slug === slug) {
      return routeLogic[key];
    }
  }
  return undefined;
}

