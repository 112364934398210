import { pluginLogicMgr } from './logicMgr';
import { PLUGIN_INIT } from '@isomorix/core-actions';
import { CoreLogicFactory } from '@isomorix/react-router';
import { addRouter } from '../router';
import { addRoutes } from '../routes';
import {
  API_CONNECTION_TYPES as API_CONN,
  PERMISSIONS, ROLES
} from '../constants';
import { MODEL_NAMES } from "@isomorix/core-config";
import { aiModelsLoader, prepareSessionModel, prepareUserModel } from "../models";
import { ApiNinjasClient } from "../connections";
import { nextTick$ } from "../helpers";
import { map, mapTo } from "rxjs/operators";
import { generateUserPermCheck } from "@isomorix/core";
import { asyncLogicResp } from "@isomorix/store";
import { defineCustomEnums } from "../graphqlTypes";

/*
 * A LogicBuilder makes it easy to create logic
 * around a particular action, in this case the
 * action that is dispatched when instantiating
 * the Plugin (a.k.a. app).
 *
 * The 2nd parameter, "./init", is the moduleName
 * to use for the logic created using this builder.
 * When a relative value is provided, as it is in this
 * case, it will use the moduleName defined when
 * creating the LogicMgr to produce the actual moduleName
 * for the logic created via this builder.
 *
 * Including a moduleName as the 2nd parameter is
 * entirely optional, it just makes it possible
 * to automatically document the logic provided
 * by this package, as well as any other stores
 * that use the logic (if any).
 *
 * Using a LogicBuilder is entirely optional.
 * Logic can also be created by simply
 * defining the desired properties on an Object,
 * or on a function's "logicDefaults" property
 * on its prototype when creating functional logic.
 */
const builder = pluginLogicMgr.getBuilder(PLUGIN_INIT, './init')
  /*
   * It is also possible to use the builder to set default
   * properties to use for any logic created using the
   * builder that does not set a value for the property.
   *
   * As an example, the commented out method would set
   * all logic that does not otherwise specify whether
   * it is pure logic to be pure logic by default.
   * This means it is no longer necessary to
   * call "setPure(true)" when creating logic,
   * only "setPure(false)" if it is not pure logic.
   *
   * Any logic defined as "pure" logic means that
   * the logic does not perform any
   * async side effects. It is "false" by default.
   */
  // .setPure(true);

function initExtraApiConnections(action) {
  const sc = action.meta.store.storeConfig;
  if (!process.env.BROWSER) {
    const { envParser } = action.payload;
    sc.clientConnections[API_CONN.API_NINJAS] = {
      host: envParser.getString('API_NINJAS_HOST'),
      apiKey: envParser.getString('API_NINJAS_KEY')
    };
  }
  sc.connections[API_CONN.API_NINJAS] = ApiNinjasClient
    .createConnMethod(
      sc.clientConnections.apiNinjas.host,
      sc.clientConnections.apiNinjas.apiKey
    );
  return action;
}

builder.useRelative('initConnections', 5)
  .setName('initExtraApiConnections')
  .setDescription('Initializes connections to additional APIs used by the app.')
  .setPure(true)
  .add(initExtraApiConnections, true);

function addCustomTypes(action) {
  const {
    payload: { pluginBuilder, types }
  } = action;
  /*
   * The @isomx/react-router package provides a really
   * nice interface for creating the CoreLogic that
   * handles our routes. See its documentation for details.
   */
  const coreLogicRoutes = CoreLogicFactory
    .init('@isomorix/attraction-marketing/coreLogic');
  addRouter(pluginBuilder, coreLogicRoutes);
  addRoutes(pluginBuilder, coreLogicRoutes);
  defineCustomEnums(types);
  coreLogicRoutes.addToEnumValues(pluginBuilder);
  /*
   * Add our custom permissions. In this case,
   * we're only defining the `slug` for the
   * permissions (which will also be its ID).
   * The `userCan()` function will be created
   * for them automatically which simply check
   * if the user has the permission associated
   * with them. But we could also define custom
   * functions as needed that would be called
   * instead of the default method that should
   * then return a `boolean` indicating whether
   * the user passes the permission check.
   */
  const {
    READ_API_ROUTES,
    READ_DOCS_ROUTE,
    READ_TRAINING_ROUTE,
    READ_APP_BUILDER,
    CREATE_AI_INTERACTION,
    READ_MODIFY_AI_SESSION_RECORD,
    CREATE_AI_SESSION,
    READ_MODIFY_AI_INTERACTION_RECORD,
    READ_ADMIN_ROUTE,
  } = PERMISSIONS;
  pluginBuilder.addPermissionUserCanValues([
    READ_API_ROUTES,
    READ_DOCS_ROUTE,
    READ_APP_BUILDER,
    READ_ADMIN_ROUTE,
    CREATE_AI_SESSION,
    {
      name: READ_TRAINING_ROUTE,
      value: function readTrainingRoute(userRole) {
        if (!userRole) return false;
        if (userRole.role.slug === ROLES.ADMIN) {
          return true;
        }
        const { user: { meta } } = userRole;
        if (!meta) return false;
        const { slug } = this;
        return !!(meta[slug] && meta[slug].value === '1')
      }
    },
    {
      name: CREATE_AI_INTERACTION,
      value: generateUserPermCheck({
        userId: 'userId',
        userPath: [ 'aiSession' ],
        isCreate: true
      })
    },
    {
      name: READ_MODIFY_AI_SESSION_RECORD,
      value: generateUserPermCheck({
        userId: 'userId',
      })
    },
    {
      name: READ_MODIFY_AI_INTERACTION_RECORD,
      value: generateUserPermCheck({
        userId: 'userId',
        userPath: [ 'aiSession' ],
      })
    }
  ]);

  return nextTick$.pipe(mapTo(action));
  // let coreLogicRoutes;
  // return nextTick$.pipe(
  //   mergeMap(() => {
  //     coreLogicRoutes = CoreLogicFactory
  //       .init('@isomorix/attraction-marketing/coreLogic');
  //     addRouter(pluginBuilder, coreLogicRoutes);
  //     return addRoutes(pluginBuilder, coreLogicRoutes);
  //   }),
  //   mergeMap(() => {
  //     coreLogicRoutes.addToEnumValues(pluginBuilder);
  //     return nextTick$;
  //   }),
  //   mergeMap(() => {
  //     /*
  //      * Add our custom permissions. In this case,
  //      * we're only defining the `slug` for the
  //      * permissions (which will also be its ID).
  //      * The `userCan()` function will be created
  //      * for them automatically which simply check
  //      * if the user has the permission associated
  //      * with them. But we could also define custom
  //      * functions as needed that would be called
  //      * instead of the default method that should
  //      * then return a `boolean` indicating whether
  //      * the user passes the permission check.
  //      */
  //     const {
  //       READ_API_ROUTES,
  //       READ_DOCS_ROUTE,
  //       READ_TRAINING_ROUTE,
  //     } = PERMISSIONS;
  //     pluginBuilder.addPermissionUserCanValues([
  //       READ_API_ROUTES,
  //       READ_DOCS_ROUTE,
  //       {
  //         name: READ_TRAINING_ROUTE,
  //         value: function readTrainingRoute(userRole) {
  //           if (!userRole) return false;
  //           if (userRole.role.slug === ROLES.ADMIN) {
  //             return true;
  //           }
  //           const { user: { meta } } = userRole;
  //           if (!meta) return false;
  //           const { slug } = this;
  //           return !!(meta[slug] && meta[slug].value === '1')
  //         }
  //       }
  //     ]);
  //     return nextTick$;
  //   }),
  //   mapTo(action)
  // )
}

/*
 * There are various "use()" functions available on the
 * builder that each do something different. One must
 * be called to begin creating logic.
 *
 * The standard "use()" creates entirely new logic.
 *
 * The "useRelative()" method will find the specified
 * logic in the "sources" associated with the LogicMgr
 * (which we added in ./logicMgr), then increments the
 * priority of that existing logic by the value provided
 * via the 2nd parameter (1 in this case). This means
 * our "addCustomTypes()" logic will execute +1
 * after the "defineScalarAndEnumTypes" logic. It
 * also sets the op for the logic to match the op
 * of the "defineScalarAndEnumTypes" logic.
 *
 * The "useProps()" method can be used when overriding
 * existing logic. The "useRelative()" method only
 * copies over the priority & op of the existing logic,
 * while the "useProps()" method copies over ALL
 * props except for the op method.
 */
builder.useRelative('defineScalarAndEnumTypes', 1)
  /*
   * It is only necessary to set a name (setName()) or
   * an id (setId()), although both can be set if
   * that is desired. Typically, it is best to
   * specify a name, and the id of the logic
   * will be created automatically by prepending
   * it with the action that this logic is
   * associated with (PLUGIN_INIT in this case).
   *
   * This means that if this logic were to be
   * added to another action type, its id will
   * be realigned accordingly.
   *
   * All logic associated with a store must have a unique
   * id, while names only need to be unique
   * per action. So by allowing the id to be generated
   * automatically, it makes this logic portable
   * to other actions as necessary.
   *
   * In this case, that's not relevant, since
   * defining custom types only occurs during
   * the plugin's initialization. But there
   * are many scenarios where reusing logic
   * makes a lot of sense.
   *
   * More importantly, though, using this pattern
   * means there's no need to manually
   * ensure your logic uses an id that is unique
   * amongst all logic associated with the store.
   * Just define logic that uses a unique name
   * for the action, and the rest is handled automatically.
   *
   * For example, using this pattern you can
   * associate a logic named "addCustomTypes" to
   * another action without causing a collision in ids.
   * Whereas, if this was set as the logic's id
   * directly, that would not be possible.
   *
   * Ultimately, a logic's name is only relevant
   * for internal use like referencing it as we
   * did when referencing the "defineScalarAndEnumTypes"
   * logic in the "useRelative()" call above. It's
   * also useful for debugging, as this will be used
   * when providing details about the logic. But
   * as far as the store itself is concerned, it
   * doesn't matter. It's only used by the store
   * to define the logic's "logicId" when that's
   * not set for the logic as described above.
   */
  .setName('addCustomTypes')
  /*
   * The description is optional, but when provided,
   * it will be used when automatically creating the
   * documentation for the logic.
   */
  .setDescription(`Adds custom types used by this app.`)
  .setPure(false) // async side effects in this logic
  /*
   * This signals we're done building this logic, and
   * by providing a function, it'll set the properties
   * we've defined, plus merge the properties in the
   * LogicBuilder's DEFAULTS Object that aren't already set
   * on this logic, before setting the result as an Object
   * on the function's "logicDefaults" property. This
   * will be used by the store to create the full
   * representation of the logic when it is added to
   * the store.
   *
   * We could also create the logic without adding
   * it to the LogicMgr by doing the following:
   *
   * const addCustomTypesLogic = builder
   *   .setOpMethod(addCustomTypes)
   *   .done();
   *
   * Or, replace ^^ done() with add() to add the logic
   * as standard logic.
   */
  .add(addCustomTypes);

/*
 * Note on functional vs. standard logic:
 *
 * It doesn't matter to the store, but functional logic
 * is useful for re-usability because it can be
 * retrieved and called directly:
 *
 * The final param, `true`, means extract the function
 * from the logic which we can then call. For example,
 * from within another logic:
 *
 * ```js
 * function someLogic(action) {
 *   const fn = pluginLogicMgr.getByName(
 *     'addCustomTypes',
 *      PLUGIN_INIT,
 *      true
 *   );
 *   fn(action);
 *   // ...any other processing, then:
 *   return action;
 * }
 * ```
 *
 * This is primarily useful between packages or
 * modules, as it provides an easy way to
 * reference/share functionality.
 *
 */

const prepareAttractionMarketingModels = (action, observer) => {
  const { payload } = action;
  const { models } = payload;
  if (models[MODEL_NAMES.SESSION]) {
    prepareSessionModel(models[MODEL_NAMES.SESSION]);
  }
  if (models[MODEL_NAMES.USER]) {
    prepareUserModel(models[MODEL_NAMES.USER]);
  }
  if (!models.AiSession) {
    return asyncLogicResp(null, action, observer);
  }
  return aiModelsLoader().pipe(
    map(module => {
      module.prepareAiSessionModel(models.AiSession);
      module.prepareAiInteractionModel(models.AiInteraction);
      return action;
    })
  )
}

builder.useProps('initModelBuilders', 5)
  .setName('prepareAttractionMarketingModels')
  .setDescription(`Handles preparing models and additional properties to existing models for the Attraction Marketing implementation.`)
  .setPure(false)
  .add(prepareAttractionMarketingModels, true);

