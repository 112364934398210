import React, { createElement, useEffect } from 'react';
import { Dialog } from "@react-md/dialog";
import { useAppSize } from "@react-md/utils";
import { useStoreSubscription } from "@isomorix/react";
import { useLayoutContext } from "@isomorix/react-md-layout";


export const ScaleDialog = React.memo(function ScaleDialog(
  {
    store
  }
) {
  useStoreSubscription(store);
  const { is2D } = useLayoutContext();
  const s = store && store.value ? store.getState() : undefined;
  useEffect(() => {
    const sv = store && store.value;
    if (!sv) return;
    const t = sv.get('updateTransitionTimeout');
    if (!t) return;
    const timeout = window.setTimeout(
      () => store.value && store.value.updateTransition(true),
      t
    );
    return () => window.clearTimeout(timeout);
  }, [ store, s && s.updateTransitionTimeout ]);
  const appSize = useAppSize();
  useEffect(() => {
      store && store.value && store.value.updateType(appSize, true);
  }, [ appSize.isPhone, appSize.isTablet, appSize.isDesktop, store ]);
  if (!s) return null;
  return createElement(
    Dialog,
    store.value.getRmdProps(is2D),
    s.Content
      ? createElement(s.Content, { store })
      : undefined
  );
});
