import React from 'react';
import { loadable } from "@isomorix/react-router";
import { LinearProgress } from "@react-md/progress";

export const Start = loadable(() => /* webpackChunkName: "start" */ import('./Start'), {
  resolveComponent: mod => mod.Start,
  fallback: (
    <>
      <LinearProgress />
      <div style={{ marginBottom: '90vh'}}>&nbsp;</div>
    </>
  )
});
