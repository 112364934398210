import React from 'react';
import { useRecordSubscription, useRenderer } from '@isomorix/react';
import { TextContainer, Typography } from '@react-md/typography';
import { CORE_LOGIC } from '../../constants';
import { PermissionDenied } from '../PermissionDenied';

export const Training = React.memo(function Training(
  {
    logic
  }
) {
  useRecordSubscription(logic);
  const renderer = useRenderer();
  const { match } = logic;
  if (!match) {
    return null;
  } else if (logic.permDenied && !logic.children) {
    return <PermissionDenied logic={logic} />
  } else if (match.isExact) {
    return (
      <TextContainer>
        <Typography type={'headline-1'}>
          Welcome To The Attraction Marketing Training Area
        </Typography>
      </TextContainer>
    )
  } else {
    return (
      <>
        { renderer.many(logic.children) }
      </>
    )
  }
});

Training.displayName = CORE_LOGIC.TRAINING;
