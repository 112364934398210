import React from 'react';
import { createComingSoonComponent } from "../ComingSoon";
import { CORE_LOGIC } from "../../constants";
import { TextContainer } from "@react-md/typography";

function Content() {
  return (
    <TextContainer>
      <h2>A Digital Revolution</h2>
      <p>
        In 2005, Mike Dillard started a revolution... he started an education company and self published a book, both named “Magnetic Sponsoring”, which disrupted the network marketing profession and ushered in a new era of digital network marketing.
      </p>
      <p>
        In the Magnetic Sponsoring book, Mike coined the phrase ‘attraction marketing’, to convey the essence of how this was different from how network marketing had been done in the past.
      </p>
      <p>
        <span style={{ fontStyle: 'italic' }}>
          “Becoming the hunted, instead of the hunter.”
        </span>
      </p>
    </TextContainer>

  )
}


export const OurTeam = createComingSoonComponent(CORE_LOGIC.OUR_TEAM, Content);
