import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import {
  HelmetProvider,
  Router as ReactRouter
} from '@isomorix/react-router';
import { MessageQueue } from '@react-md/alert';
import { Configuration } from "@react-md/layout";
import { LayoutContainer, css } from './LayoutContainer';
import { Head, insertMainStyles } from '../Head';
import { CORE_LOGIC } from '../constants';

/*
 * The ConfigurationProps are just all the props for the providers
 * joined together. The only difference is that onResize has been
 * renamed to onAppResize for the AppSizeListener
 *
 * If overrides are used, add them to this Object
 * and then:
 * <Configuration { ...overrides }>
 */
// const overrides = {};

export const Router = function Router(props) {
  const changeKey = useRecordSubscription(props.logic);
  const head = (
    <Head
      logic={props.logic}
      location={props.location}
      session={props.session}
    />
  );
  if (props.headOnly) {
    /*
     * This only occurs on the server. See the
     * @isomx/react-ssr documentation. In short,
     * this occurs first so styles and other
     * meta can be immediately flushed to
     * the client so that it can begin processing
     * while the rest of the app renders.
     *
     * And see ../Head.js for details on why
     * the main styles are only included on the server.
     *
     * The layout css is also provided so that
     * it can be rendered in <head> since it
     * is always included anyway. This could
     * also be done in logic, but it's just
     * as easy to do it this way.
     */
    insertMainStyles(props, css);
    return (
      <HelmetProvider context={props.helmetContext}>
        { head }
      </HelmetProvider>
    );
  }
  return (
    <Configuration>
      <MessageQueue position='bottom'>
        <ReactRouter { ...props } logicChangeKey={changeKey}>
          { head }
          <LayoutContainer { ...props } />
        </ReactRouter>
      </MessageQueue>
    </Configuration>
  )
}

Router.displayName = CORE_LOGIC.ROUTER;
