import React from 'react';
import { CORE_LOGIC } from "../../constants";
import { useRecordSubscription } from "@isomorix/react";
import { PermissionDenied } from "../PermissionDenied";
import { TextContainer } from "@react-md/typography";
import { LinearProgress } from "@react-md/progress";

export const Blog = React.memo(function Blog(
  {
    logic
  }
) {
  useRecordSubscription(logic);
  if (logic.permDenied) {
    return <PermissionDenied logic={logic} />
  }
  const { match } = logic;
  if (!match) return null;
  const { localProps } = logic;
  let child;
  const Blog = localProps && localProps.Blog;
  if (Blog ){
    child = (<Blog key={'blog'} logic={logic} />);
  } else if (Blog === null) {
    child = (
      <TextContainer key={'error'}>
        <h1>Oops!</h1>
        <p>Unable to access the blog posts at this time. Please refresh the page and try again.</p>
      </TextContainer>
    )
  } else {
    child = (
      <div style={{ minHeight: '95vh'}}>
        <LinearProgress key={'loading'} className={'rmd-progress--secondary'} />
      </div>
    )
  }
  return <div className={'blog-route'}>{ child } </div>;
});
Blog.displayName = CORE_LOGIC.BLOG;

