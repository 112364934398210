import React from 'react';
import { createComingSoonComponent } from "../ComingSoon";
import { CORE_LOGIC } from "../../constants";
// import { useLayoutContext } from "@isomorix/react-md-layout";
// import { useRecordSubscription } from "@isomorix/react";
// import { PermissionDenied } from "../PermissionDenied";

export const Support = createComingSoonComponent(CORE_LOGIC.SUPPORT);

// export const Support = React.memo(function Support(
//   {
//     logic
//   }
// ) {
//   useRecordSubscription(logic);
//   const { scrollWidth, scrollHeight } = useLayoutContext();
//   const { match } = logic;
//   if (!match) return null;
//   if (logic.permDenied) {
//     return <PermissionDenied logic={logic} />
//   }
//   return (
//     <iframe
//       src={'https://elitemarketingpro.zendesk.com/hc/en-us'}
//       width={`${scrollWidth}px`}
//       height={`${scrollHeight}px`}
//       style={{
//         width: `${scrollWidth}px`,
//         height: `${scrollHeight}px`,
//         border: '0px'
//       }}
//     />
//   );
// });
// Support.displayName = CORE_LOGIC.SUPPORT;
