import React from 'react';
import { CORE_LOGIC } from "../../../constants";
import { createVideoGridLogic } from "../VideoGridLogic";

export const ReelsForReal = createVideoGridLogic(
  CORE_LOGIC.TRAINING_REELS_FOR_REAL,
  {
    headline: 'Reels For Real Networkers',
  }
)
