import { coreLogicMgr } from '@isomorix/core';
import { LogicMgr } from '@isomorix/store';

/*
 * A LogicMgr is simply a convenient way
 * to build, manage, and share logic. It
 * is not necessary to use it, but it makes
 * things significantly easier.
 *
 * Ultimately, the LogicMgr is just an
 * extension of the native Array that
 * provides useful additional properties
 * for creating & managing logic.
 *
 */
export const pluginLogicMgr = LogicMgr
  /*
   * It is not necessary to declare a module,
   * but doing so makes it possible for
   * the automated documentation process
   * to properly document logic that is
   * added to this logicMgr.
   *
   * But note that when declaring a module name,
   * the full packageName is used. This will
   * automatically set the "packageName" and
   * "moduleName" properties on any LogicBuilder
   * created via this LogicMgr instance, which
   * will in-turn set the property on any logic
   * created via that LogicBuilder.
   * See the ./init module for an example.
   *
   * To initialize the LogicMgr without declaring
   * a module, simply use LogicMgr.init() instead
   * of LogicMgr.initModule().
   */
  .initModule(`@isomorix/attraction-marketing/logic`)
  /*
   * A source is logic that can be referenced
   * when creating logic. For example, to coordinate
   * logic around existing logic, or to use existing
   * logic as the basis for new logic. See the
   * ./init module for examples.
   *
   * A source can be another LogicMgr instance, or it
   * can simply be a generic Array containing logic.
   * The logic in the Array can be either standard
   * or functional logic.
   */
  .addSource(coreLogicMgr)
  /*
   * To actually add the logic to this
   * logicMgr, use the `useSource()` method.
   * This will add the logic found in the
   * source(s) to this logicMgr instance.
   */
  // .useSources()

