import { useLocationSearch } from "@isomorix/react-router";
import { useEffect, useState } from "react";

const useSessionCache = (
  session,
  name,
  createStore,
  methodSuffix,
) => {
  let store = session[`get${methodSuffix}`](name);
  if (!store) {
    const loc = session.location;
    const { searchParams } = loc;
    if (searchParams && searchParams[name]) {
      store = createStore(session);
    }
  }
  const [, setRender ] = useState(0);
  useEffect(() => {
    if (!store) return;
    const sub = store.subscribe({
      complete: () => {
        const curr = session[`get${methodSuffix}`](name);
        if (curr === store) {
          session[`set${methodSuffix}`](name, null);
        }
        setRender(curr => ++curr)
      }
    });
    return () => sub.unsubscribe();
  }, [ store, session, name, methodSuffix ]);
  useLocationSearch(store ? null : name);
  return store;
}


export function useLocationDialog(session, name, createStore) {
  return useSessionCache(session, name, createStore, 'Dialog');
}

export function useLocationForm(session, name, createStore) {
  return useSessionCache(session, name, createStore, 'Form');
}
