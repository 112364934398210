import { delay, mergeMap } from 'rxjs/operators';
import { MUTATION, PLUGIN_INIT } from '@isomorix/core-actions';
import { LOCATION_CHANGE_TYPE, MODEL_NAMES as M } from '@isomorix/core-config';
import { asyncLogicResp, loadable } from '@isomorix/store';
import { manageLayout3DLoader } from '@isomorix/react-md-layout';
import { BLOG_PAGE_SEARCH_PARAM, CORE_LOGIC } from '../../constants';
import { pluginLogicMgr } from '../../logic';
import { createDocsLoader } from '../../docs';
import { registerServiceWorker } from "../swRegister";
import { getRouteLogic, isBlogPath, nextTick$ } from "../../helpers";
import { recordLogicMgr as locRecordLogicMgr } from "@isomorix/core-location";

const builder = pluginLogicMgr.getBuilder(PLUGIN_INIT, './init');

/*
 * See `@isomx/store` package's `loadable` module for details
 * on this, including tons of examples. It makes lazy loading
 * code or any other resource super easy.
 *
 * In this case, we're using it to lazy-load the code for
 * the 3D worlds, since that's not needed on initial load.
 */
const layout3DLoader = loadable(() => import('../../router/Layout3D'));

function manageBlogPageParam(action) {
  const session = action.meta.getSession();
  /*
   * I'm handling restoring a server-rendered AI conversation
   * here, rather than in the AI chunk, since the whole
   * offline thing hasn't been fully implemented out which could
   * change __PRELOAD_STATE__, but only whether it's retrieved
   * from window. But that's not a concern here since we're
   * referencing it from the payload. But if it were done in the
   * AI chunk, it would have to assume window.__PRELOAD_STATE__,
   * which may not hold true after offline is fully implemented.
   */
  let activeAiSession = action.payload.__PRELOAD_STATE__?.__ACTIVE_AI_SESSION__;
  if (activeAiSession) {
    action.meta.instance.mutation()
      .executeWhen(action)
      .commitOnly()
      .switchTo('AiSession')
      .create([ activeAiSession ])
      .commitOnly(false);
  }
  const { location } = session;
  const locStore = action.meta.store.storeConfig.storesMap.get(location).mainStore;
  locStore.addPendingLogic(locRecordLogicMgr.findExistingBuilder(MUTATION)
    .useRelative(locRecordLogicMgr.getByName('manageStateChanges', MUTATION), -10)
    .setName('manageBlogPageParam')
    .setPure(true)
    .setOpMethod(action => {
      const sv = action.meta.store.value;
      const changeType = sv.get('changeType');
      if (
        isBlogPath(sv.get('pathname'))
        || !changeType
        || changeType === LOCATION_CHANGE_TYPE.POP
      ) {
        return action;
      }
      const idx = sv.get('search').indexOf(`${BLOG_PAGE_SEARCH_PARAM}=`);
      if (idx > -1) {
        sv.store.instance.search = sv.urlParse.set('searchParams', {
          ...sv.urlParse.searchParams,
          [BLOG_PAGE_SEARCH_PARAM]: undefined
        }).search;
      }
      return action;
    })
    .done()
  );
  return action;
}

builder.useRelative('saveModels', 10)
  .setName('manageBlogPageParam')
  .setPure(true)
  .setActionType(PLUGIN_INIT)
  .add(manageBlogPageParam, true);

function initAppLogic(action, observer) {
  const { meta } = action;
  /** @type {module:plugin.Plugin} */
  const plugin = meta.instance;
  /*
   * Locate the routerLogic and make it mutable
   * so we can set values for its fields which
   * will be automatically converted into
   * mutation data. The same could be accomplished
   * by compiling the updates in an Object
   * and then calling:
   *
   * `mutation.update(routerLogic, updateObj)`
   *
   * ...but using the record's accessors makes it easy.
   */
  const routerLogic = (
    /** @type {module:core/coreLogic.Record}*/
    plugin.mutation()
      .executeWhen(action) // automates executing the mutation
      .switchTo(M.CORE_LOGIC) // switch to the CoreLogic model
      .getMutableRecord(
        // locate the CoreLogic record for the Router, which
        // will then be mutable where we can set its properties
        // as needed.
        plugin.pluginRecord.coreLogicQuery()
          .where('name', CORE_LOGIC.ROUTER)
          .andWhereNull('routerLogicId')
          .getOneRecord()
      )
  );
  /*
   * This is just a convenience method that will
   * ensure the value for the logic's `localProps`
   * field is an Object, and if it already exists
   * (which honestly it shouldn't at this point),
   * it'll perform a shallow copy to ensure all
   * state remains immutable.
   */
  const localProps = routerLogic.getMutableLocalProps(action);
  localProps.loadLayout3DModule = manageLayout3DLoader(layout3DLoader, meta.store);
  const { location } = meta.getSession();
  localProps.loadDocsModule = createDocsLoader(
    meta.store,
    `${location.protocol}//${location.host}/static`
  );
  localProps.docsChunkName = localProps.loadDocsModule.chunkName;
  routerLogic.localProps = localProps;
  let searchParams;
  if (
    process.env.NODE_ENV === 'production'
    && window.navigator.userAgent.indexOf('Chrome-Lighthouse') < 0
    && !((searchParams = plugin.getSession().location.searchParams).noPrefetch)
  ) {
    if (typeof searchParams.disableCD !== 'undefined') {
      if (searchParams.disableCD) {
        plugin.Cookie.set('amfLetterCDDisabled', '1');
      } else {
        plugin.Cookie.remove('amfLetterCDDisabled');
      }
    }
    if (searchParams.resetCD) {
      plugin.Cookie.remove('amfLetterCD');
    }
    const logic = routerLogic.getMainInstance();
    meta.mainActionMeta.subscribe({
      complete: () => {
        const model = logic.getModel();
        const { children } = logic;
        const q = model.query()
          .selectAll('autoloadOnClient')
          .where('routerLogicId', logic.__ID)
          .andWhereNot('name', CORE_LOGIC.APP_BUILDER)
        if (children ) {
          q.andWhereNotIn(model.primaryKey, Object.keys(children))
        }
        const subscriber = ({ records, dispatchId }) => {
          if (records) {
            logic.dispatchTo(records, null, dispatchId).subscribe();
          }
        };
        q.fetch().subscribe(subscriber);
        window.loadAppBuilder = () => {
          const { children } = logic;
          if (children) {
            for(let key in children) {
              if (children[key].name === CORE_LOGIC.APP_BUILDER) {
                return;
              }
            }
          }
          model.query()
            .selectAll('autoloadOnClient')
            .where('routerLogicId', logic.__ID)
            .andWhere('name', CORE_LOGIC.APP_BUILDER)
            .fetch()
            .subscribe(subscriber);
        }
      }
    });
  }
  const $ = nextTick$.pipe(
    /*
     * dispatchCurrent() dispatches to the logic
     * using its logic.actionType property. The
     * `mainDispatchId` from our current action
     * is provided so that a new `dispatchId`
     * under the same mainAction as our action
     * will be created for the logic's action.
     * If we were to provide `action.meta.dispatchId`
     * instead, it would use the same dispatchId
     * as our action.
     */
    mergeMap(() => routerLogic.dispatchCurrent({
      onRender: action.payload.onRender,
      isReload: action.payload.isReload
    }, action.meta.mainDispatchId)),
  );
  if (
    process.env.NODE_ENV === 'production'
    && window.navigator.userAgent.indexOf('Chrome-Lighthouse') < 0
    && !location.searchParams.nosw
  ) {
    action.meta.mainActionMeta.subscribe({
      complete: () => {
        registerServiceWorker();
      }
    });
  }
  return asyncLogicResp($, action, observer);
}
builder.useProps('initAppLogic')
  .setName('initAppLogic')
  .setPure(false)
  .setDescription(`Initializes the app logic.`)
  .add(initAppLogic, true);
