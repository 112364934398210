import { ROUTE_SLUGS } from '../constants';

const docsRegExp = new RegExp(`^[\/]?${ROUTE_SLUGS.DOCS}(?:\/|$)`);

export const isDocsPath = (pathname) => docsRegExp.test(pathname)

const appBuilderRegexp = new RegExp(`^[\/]?app-builder(\/|$)`);
export const isAppBuilderPath = (pathname) => appBuilderRegexp.test(pathname);
const blogRegExp = new RegExp(`^[\/]?${ROUTE_SLUGS.BLOG}(?:\/|$)`);
const blogExactPath = `/${ROUTE_SLUGS.BLOG}`;
export const isBlogPath = (pathname, exact) => exact
  ? pathname === blogExactPath
  : blogRegExp.test(pathname);

const blogSlugRegExp = new RegExp(`^\/?${ROUTE_SLUGS.BLOG}\/posts\/(.*)$`);
export const getBlogSlug = pathname => {
  const match = pathname.match(blogSlugRegExp);
  return match && match[1] || null;
}

export const blogSlugToPathname = slug => `/${ROUTE_SLUGS.BLOG}/posts/${slug}`;
