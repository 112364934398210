import { ROUTE_SLUGS as S } from "./routeSlugs";

const baseDir = '/static';

const _trainingRt = (routeSlug) => {
  return `/${S.TRAINING}/${routeSlug.replace('training_', '')}`;
}

export const PATHS = {
  APP_CSS: `${baseDir}/css/appDenseNoFonts.css`,
  APP_CSS_FONTS: `${baseDir}/css/fontsAndIcons.css`,
  APP_BUILDER_CSS: `${baseDir}/css/appBuilderDense.css`,
  VOYAGER_WORKER: `${baseDir}/workers/voyager.worker.js`,
  TRAINING: S.TRAINING,
  TRAINING_AMF: _trainingRt(S.TRAINING_AMF),
  TRAINING_REELS_FOR_REAL: _trainingRt(S.TRAINING_REELS_FOR_REAL),
  TRAINING_SOCIAL_RETAIL_BLUEPRINT: _trainingRt(S.TRAINING_SOCIAL_RETAIL_BLUEPRINT)
};

export const IMG_BASE_PATH = '/static/img';

export const BLOG_QUERY_SEARCH_PARAM = 'blog_query';
export const BLOG_PAGE_SEARCH_PARAM = 'blog_page';
