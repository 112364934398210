import React from 'react';
import { CORE_LOGIC } from "../../../constants";
import { createVideoGridLogic } from "../VideoGridLogic";

export const SocialRetailBlueprint = createVideoGridLogic(
  CORE_LOGIC.TRAINING_SOCIAL_RETAIL_BLUEPRINT,
  {
    headline: 'Social Retail Blueprint',
  }
)
