import React, { createElement } from 'react';
import { loadable } from "@isomorix/react-router";
import { LinearProgress } from "@react-md/progress";
import { useRecordSubscription } from "@isomorix/react";
import { PermissionDenied } from "../PermissionDenied";
import { CORE_LOGIC, MAIN_ELEM_SCROLL_ID, ROUTE_SLUGS } from "../../constants";
import { useOnClickRemoveAccess, useTrainingAccess } from "../../hooks";
import { Button } from "@react-md/button";
import { FontIcon, TextIconSpacing } from "@react-md/icon";


const VideoGrid = loadable(() => /* webpackChunkName: "videoGrid" */ import('../../components/VideoGrid'),
  {
    resolveComponent: mod => mod.VideoGrid,
    ssr: true,
    fallback: <LinearProgress />
  }
);

export function createVideoGridLogic(displayName, props) {
  if (!props) props = {};
  props.preset = displayName;
  let routeSlug;
  switch(displayName) {
    case CORE_LOGIC.TRAINING_AMF:
      routeSlug = ROUTE_SLUGS.TRAINING_AMF;
      break;
    case CORE_LOGIC.TRAINING_REELS_FOR_REAL:
      routeSlug = ROUTE_SLUGS.TRAINING_REELS_FOR_REAL;
      break;
    case CORE_LOGIC.TRAINING_SOCIAL_RETAIL_BLUEPRINT:
      routeSlug = ROUTE_SLUGS.TRAINING_SOCIAL_RETAIL_BLUEPRINT;
      break;
    default:
      throw new Error(`Unrecognized CoreLogic ${displayName}`);
  }
  const Component = React.memo(function({ logic }) {
    useRecordSubscription(logic);
    const hasAccess = useTrainingAccess(routeSlug);
    const onClickRefund = useOnClickRemoveAccess(routeSlug);
    const { match } = logic;
    if (!match) return null;
    if (!hasAccess) {
      return createElement(VideoGrid, {
        ...props,
        PermissionDenied
      })
      // return <PermissionDenied logic={logic} />
    }
    return (
      <>
        { createElement(VideoGrid, props) }
        <div
          style={{
            marginTop: '2rem',
            marginBottom: '2rem',
            textAlign: 'center'
          }}
        >
          <Button
            theme={'clear'}
            themeType={'flat'}
            onClick={e => {
              onClickRefund(e);
              const elem = document.getElementById(MAIN_ELEM_SCROLL_ID);
              if (elem) {
                elem.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              }
            }}
          >
            <TextIconSpacing
              icon={<FontIcon>delete_forever</FontIcon>}
            >
              Refund
            </TextIconSpacing>

          </Button>
        </div>
      </>
    );
  })
  Component.displayName = displayName;
  return Component;
}
