import { loadable } from '@isomorix/store';
import { of } from 'rxjs';
import { mapTo, mergeMap } from 'rxjs/operators';

const _loadDocs = loadable(() => import('./docsChunk'));

export const createDocsLoader = (pluginStore, docsPath) => {
  pluginStore = pluginStore.mainStore;
  let mod;
  function loadDocsModule(action) {
    if (mod) return of(mod);
    return _loadDocs(action).pipe(
      mergeMap(module => {
        if (mod) return of(mod);
        mod = module;
        const cache = new module.ContentCache(docsPath);
        module.setDefaultContentCache(cache);
        /*
         * As of 5/11/2022 the search functionality
         * is not server-rendered. So only need to
         * load the instantSearch connection
         * in the browser. But on the server it's
         * pretty much already there, since it
         * initializes the MEILISEARCH connection
         * to determine the apiKey to use on the client.
         */
        if (process.env.BROWSER) {
          return pluginStore
            .instance
            .loadMeilisearchConnections()
            .pipe(mapTo(mod));
        }
        return of(mod);
      })
    )
  }
  loadDocsModule.chunkName = _loadDocs.chunkName;
  return loadDocsModule;
}
