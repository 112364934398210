import React from 'react';
import { Avatar } from '@react-md/avatar';
import { UserMenu } from '@isomorix/react-md-user';
import { SVGIcon } from "@react-md/icon";
import { ROLE_AVATAR_COLORS } from './constants';
// import { useAppSize } from "@react-md/utils";


function RoleAvatar(
  {
    role,
    className,
    isMenuItem,
    nameMaxLength,
    initials
  }
) {
  let cn = className
    ? `${className} user-menu-role-avatar`
    : 'user-menu-role-avatar';
  if (isMenuItem) {
    return (
      <Avatar
        className={cn}
        color={ROLE_AVATAR_COLORS[role.slug]}
      >
        { role.name.charAt(0) }
      </Avatar>
    )
  } else {
    let child;
    if (nameMaxLength === 0) {
      cn += ` user-menu-role-avatar--initials`;
      child = initials;
    } else {
      child = role.name.charAt(0);
    }
    return (
      <Avatar
        className={cn}
        color={ROLE_AVATAR_COLORS[role.slug]}
      >
        { child }
      </Avatar>
    )
  }
}

export const UserAppBarAction = React.memo(function UserAppBarAction(props) {
  return (
    <UserMenu
      { ...props }
      nameMaxLength={0}
      loginButtonType={'icon'}
      loginButtonChildren={(
        <SVGIcon>
          <g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><circle cx="10" cy="8" r="4"/><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z"/><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"/></g></g>
        </SVGIcon>
      )}
      RoleAvatar={RoleAvatar}
    />
  )

  // const appSize = useAppSize();
  // if (appSize.isPhone || true) {
  //   return (
  //     <UserMenu
  //       { ...props }
  //       nameMaxLength={0}
  //       loginButtonType={'icon'}
  //       loginButtonChildren={(
  //         <SVGIcon>
  //           <g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><circle cx="10" cy="8" r="4"/><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z"/><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z"/></g></g>
  //         </SVGIcon>
  //       )}
  //       RoleAvatar={RoleAvatar}
  //     />
  //   )
  // } else {
  //   return (
  //     <UserMenu
  //       { ...props }
  //       nameMaxLength={8}
  //       RoleAvatar={RoleAvatar}
  //     />
  //   )
  // }
})
