import { adminRouteLogicMgr as logicMgr } from "./logicMgr";
import { MUTATION } from "@isomorix/core-actions";
import { asyncLogicResp, loadable } from "@isomorix/store";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";


const builder = logicMgr.getBuilder(MUTATION, './mutation');

const chunk = loadable(() => import('../adminChunk'));

function ensureResources(action, observer) {
  const logic = action.meta.instance;
  if (logic.match && !logic.permDenied) {
    return chunk(action).pipe(
      mergeMap(module => {
        return module
          ? module.addToLogic(action, builder)
          : of(action);
      })
    )
  } else {
    return asyncLogicResp(null, action, observer);
  }
}

builder.useRelative('trxOptimisticExecute', 100)
  .setName('ensureResources');
if (process.env.NODE_ENV !== 'production') {
  builder.setDescription('Ensures Admin content is available to render the "admin" route.');
}
builder.add(ensureResources, true);
