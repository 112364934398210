import React, { useMemo, useRef } from 'react';
import scssVariables from "@react-md/theme/dist/scssVariables";
import {
  getBestMatch,
  Helmet,
  getThemeType,
  findThemeType,
  setThemeType
} from '@isomorix/react-router';
import { useRecordSubscription } from '@isomorix/react';
import { IMG_BASE_PATH, PATHS } from "./constants";

const defaultMeta = {
  title: 'Attraction Marketing',
  description: `AttractionMarketing.com is the premier online community for today’s Digital Network Marketer. It’s a place where you can learn...`,
  keywords: 'attraction marketing, internet marketing, magnetic sponsoring',
  author: 'Josh Cope',
};

const getColorVar = (color, hue) => {
  switch(hue) {
    case 100:
    case 200:
    case 400:
    case 700:
      return scssVariables[`rmd-${color}-a-${hue}`];
    default:
      return scssVariables[`rmd-${color}-${hue}`];
  }
}

/**
 * Optionally support a changing color scheme by
 * setting the css vars on the root. Note that
 * this will override whatever is set in
 * `./_variables.scss`.
 *
 * @param {Object} colorProps - Any Object, but
 * typically this would be the `logic.props` Object
 * from the Router logic.
 * @returns {string}
 * @ignore
 */
const getHtmlStyle = (colorProps) => {
  const {
    primaryColor = 'cyan',
    primaryColorHue = 500,
    secondaryColor = 'orange',
    secondaryColorHue = 700,
  } = colorProps;
  let style = primaryColor.charAt(0) === '#'
    ? `--rmd-theme-primary: ${primaryColor}; `
    : `--rmd-theme-primary: ${getColorVar(primaryColor, primaryColorHue)}; `;
  style += `--rmd-theme-secondary: ${getColorVar(secondaryColor, secondaryColorHue)};`;
  return style;
}

function renderHeader(
  {
    routeMeta,
    themeType,
    href,
    colorProps
  }
) {
  const meta = routeMeta
    ? { ...defaultMeta, ...routeMeta }
    : defaultMeta;
  return (
    <Helmet prioritizeSeoTags>
      <html
        lang="en"
        dir="ltr"
        className={themeType ? `${themeType}-theme` : undefined}
        style={getHtmlStyle(colorProps)}
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <meta charSet="utf-8"/>
      <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <meta name="author" content={meta.author} />
      <link rel="canonical" href={href} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={href} />
      <meta property="twitter:site" content="localhost"/>
      <meta property="twitter:creator" content={meta.author}/>
      <meta property="twitter:title" content={meta.title}/>
      <link rel="manifest" href="/site.webmanifest" />
      <link
        rel="apple-touch-icon"
        href={`${IMG_BASE_PATH}/icons/icon-192x192.png`}
      />
      <meta name="theme-color" content="#011834" />
      {/*<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" crossOrigin />*/}
      {/*<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:400,500:700&display=swap" crossOrigin />*/}
      <link
        key='preload-app-styles'
        rel={'preload'}
        href={`${PATHS.APP_CSS}`}
        as={'style'}
      />
      <link
        key={'mainApp'}
        rel="stylesheet"
        href={`${PATHS.APP_CSS}`}
        type="text/css"
      />
    </Helmet>
  )
}

/*
 * insertMainStyles() is only called by the Router (./router/Router.js)
 * when `props.headOnly === true`. This allows the
 * styles to be flushed to the client right away while
 * the rest of the app is rendered.
 *
 * Importing the main styles and including them is only
 * done on the server to prevent including them in the
 * client bundle. They'll be inserted by the server in
 * <head /> and therefore don't need to be included again.
 * If for some reason the main styles change once on the
 * client, this can be altered as needed.
 *
 * The only other server-side difference is retrieving
 * the appropriate themeType (light or dark).
 */
let insertMainStyles, useThemeType;


if (process.env.BROWSER) {
  useThemeType = (session) => {
    const firstRender = useRef(true);
    useRecordSubscription(session);
    let themeType;
    if (firstRender.current) {
      firstRender.current = false;
      themeType = getThemeType(session) || findThemeType(session, true);
      setThemeType(session, themeType);
    } else {
      themeType = getThemeType(session);
    }
    return themeType;
  }
} else {
  // let mainCss;
  /*
   * This try/catch is just so that if
   * the server is started outside a
   * bundle (i.e., `node ./server/index.js`),
   * it won't prevent the server from starting.
   */
  // try {
  //   mainCss = require('./index.scss');
  // } catch(e) {}

  insertMainStyles = (props, layoutCss) => {
    // props.insertCss(mainCss);
    props.insertCss(layoutCss);
  }
  /*
   * findThemeType() attempts to retrieve from
   * Http.request (i.e., a cookie), otherwise
   * it retrieves from the session. If that's
   * not set either, themeType will be `undefined`.
   */
  useThemeType = (session) => (
    findThemeType(session) || getThemeType(session) || 'light'
  );
}

export const Head = function Head(
  {
    logic,
    location,
    session
  }
) {
  const themeType = useThemeType(session);
  /*
   * If the page meta in <head /> can change
   * other than when the location pathname
   * or searchParams change
   * (i.e., due to a change in location state)
   * remove the useMemo() hook. Otherwise,
   * this helps prevent unnecessary re-rendering
   * of all the head meta.
   */
  const href = location.href;
  const pathname = location.pathname;
  const searchParams = location.searchParams;
  const logicProps = logic.props;
  return useMemo(() => renderHeader({
    pathname,
    href,
    searchParams,
    routeMeta: getBestMatch(logic).route.meta,
    themeType,
    colorProps: logicProps,
  }), [ logic, href, pathname, searchParams, themeType, logicProps ]);
}

export { insertMainStyles }
