import React from 'react';
import { useRecordSubscription } from '@isomorix/react';
import { LoginDialog } from '@isomorix/react-md-user';
import { CORE_LOGIC } from '../../constants';

export const UserRoute = React.memo(function UserRoute(
  {
    logic
  }
) {
  useRecordSubscription(logic);
  const { match } = logic;
  const searchParams = match && match.searchParams;
  return (searchParams && searchParams.user_login
    ? <LoginDialog />
    : null
  );
});

UserRoute.displayName = CORE_LOGIC.USER;
