import React, { createElement, useCallback } from 'react';
import { useSessionContext } from "@isomorix/react-router";
import {
  AI_CHAT_DIALOG_NAME,
  AI_CHAT_FULLSCREEN_SEARCH_PARAM
} from "./constants";
import { SVGIcon } from "@react-md/icon";
import { AppBarAction } from "@react-md/app-bar";

export const AiChatAppBarAction = React.memo(function AiChatAppBarAction(
  {
    onClick: propsOnClick,
    children,
    ...props
  }
) {
  const session = useSessionContext();
  props.key = 'aiChat';
  if (!props['aria-label']) {
    props['aria-label'] = 'Chat with us!';
  }
  props.onClick = useCallback((e) => {
    const loc = session.location;
    if (propsOnClick && propsOnClick(e) === false) return;
    const { searchParams } = loc;
    if (searchParams[AI_CHAT_DIALOG_NAME]) {
      loc.pushSearch({
        [AI_CHAT_DIALOG_NAME]: undefined,
        [AI_CHAT_FULLSCREEN_SEARCH_PARAM]: undefined,
        [`${AI_CHAT_DIALOG_NAME}X`]: undefined,
        [`${AI_CHAT_DIALOG_NAME}Y`]: undefined,
      })
    } else {
      const store = session.getDialog(AI_CHAT_DIALOG_NAME);
      const fs = store && store.get('fullscreen');
      loc.pushSearch({
        [AI_CHAT_DIALOG_NAME]: true,
        [AI_CHAT_FULLSCREEN_SEARCH_PARAM]: fs || undefined,
        [`${AI_CHAT_DIALOG_NAME}X`]: e.clientX || e.x,
        [`${AI_CHAT_DIALOG_NAME}Y`]: e.clientY || e.y,
      })
    }
  }, [ session, propsOnClick ]);
  if (!children) {
    children = (
      <SVGIcon>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" />
      </SVGIcon>
    )
  }
  props.children = children;
  return createElement(AppBarAction, props);
})
