import { useSessionContext } from "@isomorix/react-router";
import { useCallback, useEffect, useState } from "react";
import { useUserMeta, useUserRole } from "@isomorix/react";
import { useTrainingAccess } from "./training";
import { PATHS, ROUTE_SLUGS } from "../constants";

const userMetaKeys = [
  'phone',
  'amfCheckout'
];

export function useAmfLetterLinks() {
  const session = useSessionContext();
  const checkout = session.getForm('checkout');
  const userRole = useUserRole(session);
  const extraMeta = useUserMeta(userMetaKeys);
  let phone, amfCheckout;
  if (extraMeta) {
    for(let key in extraMeta) {
      if (extraMeta[key].key === 'phone') {
        phone = extraMeta[key];
      } else {
        amfCheckout = extraMeta[key];
      }
    }
  }
  const [, forceRender ] = useState(0);
  const hasAccess = useTrainingAccess(ROUTE_SLUGS.TRAINING_AMF);
  useEffect(() => {
    if (hasAccess) return;
    let sub;
    if (checkout) {
      sub = checkout.subscribe({
        complete: () => {
          setTimeout(() => sub && forceRender(prev => ++prev), 500);
        }
      })
    } else {
      sub = session.subscribeToCache('checkout').subscribe((form) => {
        /*
         * Must be async, since components will register
         * forms outside useEffect(). So immediately
         * triggering a render here would cause a
         * react error that it can't update the
         * component while rendering a different component.
         */
        setTimeout(() => forceRender(prev => ++prev), 5);
      })
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
        sub = undefined;
      }
    }
  }, [ checkout, hasAccess ]);
  const openLetter = useCallback((e) => {
    if (hasAccess) {
      session.location.push(PATHS.TRAINING_AMF);
    } else {
      session.location.pushSearch({
        amfLetterSpecial: true,
        amfLetterSpecialX: e.clientX || e.x,
        amfLetterSpecialY: e.clientY || e.y
      });
    }
  }, [ hasAccess, session ]);
  const openCheckout = useCallback((e) => {
    if (!session.userRoleId) {
      openLetter(e);
    } else if (hasAccess) {
      session.location.push(PATHS.TRAINING_AMF);
    } else {
      const checkout = session.getForm('checkout');
      session.location.pushSearch({
        amfLetterSpecial: true,
        amfLetterSpecialX: e.clientX || e.x,
        amfLetterSpecialY: e.clientY || e.y,
        checkout: true,
        checkoutStep: checkout && checkout.value.isStep1Complete()
          ? 2
          : 1,
        checkoutProduct: 'amfSpecial'
      });
    }
  }, [ hasAccess, openLetter ]);
  const user = userRole && userRole.user;
  const checkoutOk = user && user.lastName && phone && phone.value
    && (!amfCheckout || amfCheckout.value === '1')
  const openTraining = useCallback(e => {
    if (hasAccess) {
      session.location.push(PATHS.TRAINING_AMF);
    } else if (checkoutOk && checkout) {
      openCheckout(e);
    } else {
      openLetter(e);
    }
  }, [ hasAccess, openCheckout, openLetter, checkoutOk, checkout ]);
  return {
    openLetter,
    openCheckout,
    openTraining,
    hasCheckout: !hasAccess && amfCheckout && amfCheckout.value === '1',
    checkout: checkoutOk
      ? checkout
      : null,
    hasAccess,
    user,
  }
}
