import { ROLES as DEFAULT_ROLES } from '@isomorix/core-config';
import { PKG_SLUG } from './pkg';


export const ROLES = {
  /*
   * These 2 are the only default roles.
   */
  ADMIN: DEFAULT_ROLES.ADMIN,
  SUBSCRIBER: DEFAULT_ROLES.SUBSCRIBER,
  // add additional roles as needed.
  CONTRIBUTOR: 'contributor',
};

export const PERMISSIONS = {
  READ_API_ROUTES: 'read_api_routes',
  READ_DOCS_ROUTE: 'read_docs_route',
  READ_TRAINING_ROUTE: 'read_training_route',
  READ_APP_BUILDER: 'read_app_builder',

  CREATE_AI_SESSION: 'create_ai_session',
  CREATE_AI_INTERACTION: 'create_ai_interaction',
  READ_MODIFY_AI_SESSION_RECORD: 'read_modify_ai_session_record',
  READ_MODIFY_AI_INTERACTION_RECORD: 'read_modify_ai_interaction_record',
  READ_ADMIN_ROUTE: 'read_admin_route',
};

/*
 * Used by the UserMenu app bar action
 * to display an avatar representing
 * the user's role.
 */
export const ROLE_AVATAR_COLORS = {
  [ROLES.ADMIN]: 'red',
  [ROLES.SUBSCRIBER]: 'purple',
  [ROLES.CONTRIBUTOR]: 'teal'
};


/*
 * Permission slugs have to be globally unique
 * because it is also used as the permission's ID.
 *
 * But roles only have to be unique on a plugin level.
 */
for(let key in PERMISSIONS) {
  PERMISSIONS[key] = `${PKG_SLUG}_${PERMISSIONS[key]}`
}
