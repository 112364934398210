import './universalEntryRequired';
import sqlConnector from "@isomorix/connector-sql";
import reactRouter from '@isomorix/react-router';
import meilisearch from '@isomorix/meilisearch';
import jsdoc from '@isomorix/jsdoc-logic';
import { CoreBuilder } from '@isomorix/core';

/**
 *
 * @param {module:core.storeConfig|boolean} storeConfigOrIsServer
 * @param {module:store.LogicMgr} pluginLogicMgr
 * @returns {module:core.CoreBuilder|module:plugin.PluginBuilder}
 * @ignore
 */
export function initBuilder(storeConfigOrIsServer, pluginLogicMgr) {
  return CoreBuilder
    .init(storeConfigOrIsServer)
    .setStoreConfig('processEnv', process.env)
    .setPackageName('@isomorix/attraction-marketing')
    .setModuleName('@isomorix/attraction-marketing')
    .setEnvParser()
    .addPendingLogic(pluginLogicMgr)
    .addDefaultLogic()
    .addPackage(reactRouter)
    .addPackage(meilisearch)
    .addPackage(jsdoc)
    .addPackage(sqlConnector)

}

export { CoreBuilder };
