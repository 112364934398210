import React from 'react';
import { useUserMeta, useUserRole } from "@isomorix/react";
import { ROLES } from "../constants";
import { useAddMessage } from "@react-md/alert";
import { useSessionContext } from "@isomorix/react-router";
import { useCallback } from "react";

export function useTrainingAccess(routeSlug, isFBAccess) {
  const userRole = useUserRole();
  const meta = useUserMeta(routeSlug, true);
  if (!isFBAccess && !meta && userRole && userRole.role.slug === ROLES.ADMIN) {
    return true;
  }
  return !!(meta && meta.value === '1');
}

export function useFBCommunityAccess() {
  return useTrainingAccess('joinCommunity', true);
}

export function useOnClickRemoveAccess(defaultKey) {
  const addMessage = useAddMessage();
  const session = useSessionContext();
  return useCallback((key) => {
    if (typeof key !== 'string') {
      key = defaultKey;
    }
    const userRole = session && session.userRole;
    if (!userRole) return;
    const meta = userRole.user.meta;
    if (!meta) return;
    const rec = meta[key];
    if (!rec) return;
    addMessage({
      children: (
        <>
          <p>Access Removed</p>
          <p>
            Undo: ctrl + z <br/>
            Redo: ctrl + shift + z
          </p>
        </>
      ),
      action: {
        themeType: 'contained',
        children: 'Undo',
        onClick: () => {
          session.plugin.pluginInstance.timeTravel(-1);
        }
      },
      twoLines: true,
    });
    /*
     * In case a component needs to monitor
     * for when it's complete. For example,
     * handling responseActions with Ai chat.
     */
    return rec.setState({ value: '0' }, true);
  }, [ addMessage, session, defaultKey ]);
}
