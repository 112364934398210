import { PKG_SLUG } from './pkg';
import { ROUTE_SLUGS } from "./routeSlugs";

const CORE_LOGIC = {
  ROUTER: 'app_router',
  API_DETAILS: 'app_api_details_route',
  DOCS: 'app_docs_route',
  GRAPHQL_PLAYGROUND: 'app_graphql_playground_route',
  GRAPHQL_VOYAGER: 'app_graphql_voyager_route',
  USER: 'app_user_route',

  OUR_TEAM: `app_${ROUTE_SLUGS.OUR_TEAM}`,
  ABOUT_US: `app_${ROUTE_SLUGS.ABOUT_US}`,
  EVENTS: `app_${ROUTE_SLUGS.EVENTS}`,
  SUPPORT: `app_${ROUTE_SLUGS.SUPPORT}`,
  BLOG: `app_${ROUTE_SLUGS.BLOG}`,
  START: `app_${ROUTE_SLUGS.START}`,
  GROUP: `app_${ROUTE_SLUGS.GROUP}`,
  AMF_LETTER_3_DAY_SPECIAL: `app_${ROUTE_SLUGS.AMF_LETTER_3_DAY_SPECIAL}`,

  TRAINING: `app_${ROUTE_SLUGS.TRAINING}`,
  TRAINING_AMF: `app_${ROUTE_SLUGS.TRAINING_AMF}`,
  TRAINING_REELS_FOR_REAL: `app_${ROUTE_SLUGS.TRAINING_REELS_FOR_REAL}`,
  TRAINING_SOCIAL_RETAIL_BLUEPRINT: `app_${ROUTE_SLUGS.TRAINING_SOCIAL_RETAIL_BLUEPRINT}`,

  APP_BUILDER: `app_${ROUTE_SLUGS.APP_BUILDER}`,

  AI: `app_${ROUTE_SLUGS.AI}`,
  ADMIN: `app_${ROUTE_SLUGS.ADMIN}`
}

for(let key in CORE_LOGIC) {
  CORE_LOGIC[key] = `${PKG_SLUG}_${CORE_LOGIC[key]}`;
}

export { CORE_LOGIC };
