import { Observable, of } from "rxjs";
import { filter, mapTo, mergeMap } from "rxjs/operators";
import { toMainInstance } from "@isomorix/operators";
import { API_CONNECTION_TYPES, CORE_LOGIC, ROLES } from "../constants";
import {
  recordLogicMgr as sessRecLogicMgr
} from "@isomorix/core-session";
import { MODEL_NAMES } from "@isomorix/core-config";
import { CORE_LOGIC_TYPES } from "@isomorix/core-logic";
import { MUTATION } from "@isomorix/core-actions";

const completeStores = (obj) => {
  for(let name in obj) {
    if (obj[name]) {
      obj[name].complete();
      obj[name] = undefined;
    }
  }
}

// const completeObservers = (function() {
//   const keys = [ 'formsObservers', 'dialogsObservers' ];
//   return cache => {
//     let array, copy;
//     for(let key of keys) {
//       if ((array = cache[key]) && array.length) {
//         copy = array.slice();
//         for(let obs of copy) {
//           obs.complete();
//         }
//         cache[key].length = 0;
//       }
//     }
//   }
// })();

const completeCache = cache => {
  if (cache.observers && cache.observers.length) {
    const observers = cache.observers.slice();
    cache.observers = undefined;
    for(let obs of observers) {
      obs.complete();
    }
  }
  if (cache.forms) completeStores(cache.forms);
  if (cache.dialogs) completeStores(cache.dialogs);
  if (cache.recStoreSub) {
    cache.recStoreSub.unsubscribe();
    cache.recStoreSub = undefined;
  }
}


function prepareSessionRecord(modelBuilder) {
  const recBuilder = modelBuilder.getRecordBuilder();
  const recMethods = recBuilder.Class.prototype;
  const { storesMap } = modelBuilder;
  const cacheMap = new Map();
  const plugin = modelBuilder.parentStore.mainInstance;
  if (process.env.NODE_ENV !== 'production') {
    recBuilder.accessors.__cacheMap = {
      get() { return cacheMap },
      set: undefined,
      enumerable: false,
      configurable: false
    };
  }
  let recToStore = rec => storesMap.get(rec).mainStore;
  if (!process.env.BROWSER) {
    recToStore = rec => storesMap.get(rec);
  }
  const createCache = store => {
    const cache = { forms: {}, dialogs: {} };
    cache.recStoreSub = store.subscribe({
      complete: () => {
        cache.recStoreSub = undefined;
        completeCache(cache);
      }
    })
    cacheMap.set(store, cache);
    return cache;
  }
  const getCache = (store, key, name, ifExists) => {
    let cache = cacheMap.get(store);
    if (!cache) {
      if (ifExists) return undefined;
      cache = createCache(store);
    }
    if (!key) return cache;
    if (!name) return cache[key];
    return cache[key][name];
  }
  const setCache = (store, key, name, value) => {
    let cache = getCache(store, key);
    const prevValue = cache[name];
    if (!prevValue) {
      cache[name] = value;
    } else if (prevValue === value) {
      return;
    } else {
      cache[name] = value;
      /*
       * Guard against multiple calls
       * to complete due to bug in
       * rxjs that always assumes
       * `this.observers` exists
       * after emitting "complete"
       * to subscribers, which it
       * won't if unsubscribe()
       * occurs during the loop.
       */
      if (!prevValue.isStopped) {
        prevValue.complete();
      }
    }
    ///////////////////////////////////////////////
    // Uncomment to enable subscribing to a
    // form or dialog appearing in the cache.
    ///////////////////////////////////////////////
    // let observers = cache[`${key}Observers`];
    // if (observers && observers.length) {
    //   observers = observers.slice();
    //   const next = {
    //     name,
    //     value,
    //     prevValue,
    //     isForm: key === 'forms',
    //     isDialog: key === 'dialogs'
    //   };
    //   for(let obs of observers) {
    //     obs.next(next);
    //   }
    // }
    cache = cacheMap.get(store);
    let observers = cache.observers;
    if (observers && observers.length) {
      observers = observers.slice();
      const next = {
        name,
        value,
        prevValue,
        isForm: key === 'forms',
        isDialog: key === 'dialogs'
      };
      for(let obs of observers) {
        obs.next(next);
      }
    }
  }
  recMethods.getForm = function(name) {
    return getCache(recToStore(this), 'forms', name, true);
  }
  recMethods.setForm = function(name, form) {
    setCache(recToStore(this), 'forms', name, form);
  }
  recMethods.getDialog = function(name) {
    return getCache(recToStore(this), 'dialogs', name, true);
  }
  recMethods.setDialog = function(name, dialog) {
    setCache(recToStore(this), 'dialogs', name, dialog);
  }

  recMethods.setAuthCode = function(code, value, mutation) {
    const dispatchId = this.__dispatchId;
    let m;
    if (!mutation) {
      m = plugin.mutation(dispatchId).switchTo(this.__typename);
    } else {
      m = mutation.switchTo(this.__typename);
    }
    const { meta } = this;
    let { authCodes } = meta;
    if (!authCodes) {
      authCodes = [ code ];
    } else if (code) {
      authCodes = [ ...authCodes, code ];
    }
    const sess = m.update(this, {
      meta: {
        ...meta,
        auth: typeof value === 'undefined' ? 'overview' : value,
        authCodes,
      }
    });
    if (mutation) {
      return sess;
    }
    m.execute();
    return dispatchId
      ? m.ofComplete().pipe(mapTo(sess))
      : m.ofComplete(true).pipe(mapTo(this));
  }
  /////////////////////////////////////////////////////
  // Uncomment to enable subscribing to a
  // form or dialog appearing in the cache.
  //
  // Also, handle completing them in completeCache().
  ////////////////////////////////////////////////////
  // const subscribeToCache = (store, key) => {
  //   return new Observable(observer => {
  //     if (store.isStopped || store.closed) {
  //       observer.complete();
  //       return;
  //     }
  //     const cache = getCache(store);
  //     let array = cache[`${key}Observers`];
  //     if (!array) {
  //       array = [ observer ];
  //       cache[`${key}Observers`] = array;
  //     } else {
  //       array.push(observer);
  //     }
  //     return () => {
  //       const array = cache[`${key}Observers`];
  //       if (!array) return;
  //       const idx = array.indexOf(observer);
  //       if (idx > -1) {
  //         array.splice(idx, 1);
  //       }
  //     }
  //   })
  // }

  recMethods.subscribeToCache = function(nameOrNames) {
    const store = recToStore(this);
    const cache = getCache(store);
    const src$ = new Observable(observer => {
      let array = cache.observers;
      if (!array) {
        array = [ observer ];
        cache.observers = array;
      } else {
        array.push(observer);
      }
      return () => {
        array = cache.observers;
        if (!array) return;
        const idx = array.indexOf(observer);
        if (idx > -1) {
          array.splice(idx, 1);
        }
      };
    });
    if (!nameOrNames) return src$;
    const names = Array.isArray(nameOrNames)
      ? nameOrNames
      : [ nameOrNames ];
    return src$.pipe(filter(payload => names.indexOf(payload.name) > -1))
  }

  // const aiModelNames = [ 'AiSession', 'AiInteraction' ];
  // if (process.env.BROWSER) {
  //   recMethods.ensureAiModels = function(action) {
  //     if (plugin.switchTo('AiSession')) {
  //       return of(action);
  //     }
  //     let src$;
  //     if (!action) {
  //       const ctrl = plugin.mutation();
  //       src$ = ctrl.activateModels(aiModelNames).pipe(
  //         mergeMap(() => {
  //           ctrl.execute();
  //           return ctrl.ofComplete(true);
  //         })
  //       );
  //     } else {
  //       src$ = action.payload.mutation.controller.activateModels(aiModelNames);
  //     }
  //     return src$.pipe(mapTo(action));
  //   }
  // } else {
  //   recMethods.ensureAiModels = function(action) {
  //     if (action) {
  //       addPreloadStateModels(
  //         action,
  //         action.meta.instance.__pluginSlug,
  //         aiModelNames
  //       )
  //     }
  //     return of(action);
  //   }
  // }


  function fetchIpData() {
    if (typeof this.ipIsValid === 'boolean') {
      return of(this);
    }
    const main = this.getMainInstance();
    return new Observable(observer => {
      const sess = this.__ID ? this : main;
      const sub = sess.subscribeAndPersist(sess => {
        if (typeof sess.ipIsValid === 'boolean') {
          sub.unsubscribe();
          observer.next(sess);
          observer.complete();
        }
      });
      plugin
        .getConnection(API_CONNECTION_TYPES.API_NINJAS)
        .ipLookup(null, sess, main)
        .subscribe();
      return () => sub.unsubscribe();
    });
  }
  if (process.env.BROWSER) {
    recMethods.fetchIpData = function() {
      if (typeof this.ipIsValid === 'boolean') {
        return of(this);
      }
      const store = recToStore(this);
      const query = store.parentInstance.query();
      return query.where(query.primaryKey, this.__ID)
        .selectAll(true)
        .fetchOneRecord()
        .pipe(
          toMainInstance(true),
          mergeMap(sess => {
            if (typeof sess.ipIsValid === 'boolean') {
              return of(sess);
            }
            return fetchIpData.call(sess);
          })
        )
    }
  } else {
    recMethods.fetchIpData = fetchIpData;
    function manageCustomLoginOut(action) {
      const sv = action.meta.store.value;
      const prev = sv.getPrev('userRoleId');
      const curr = sv.get('userRoleId');
      if (!curr || prev === curr || prev && curr) {
        return action;
      }
      const sessMeta = sv.get('meta');
      const { auth, authCodes } = sessMeta;
      const code = authCodes && authCodes[authCodes.length - 1];
      if (!auth || !code) return action;
      const sess = sv.store.mainStore.instance;
      const userRole = sv.store.instance.userRole.getMainInstance();
      action.meta.mainActionMeta.subscribe(() => {
        if (sess.userRole !== userRole) return;
        const { authCodes } = plugin.switchTo(MODEL_NAMES.CORE_LOGIC)
          .query()
          .where('pluginId', plugin.__ID)
          .andWhere('name', CORE_LOGIC.ROUTER)
          .andWhere('type', CORE_LOGIC_TYPES.ROUTER)
          .getOneRecord()
          .props;
        let roleSlug = authCodes[code];
        if (roleSlug === ROLES.ADMIN) {
          roleSlug = ROLES.CONTRIBUTOR;
        }
        if (roleSlug === userRole.role.slug) return;
        const q = userRole.user.rolesDataQuery()
          .where('role', { slug: roleSlug })
          .noDestroy();
        const extraUserRole = q.getOneRecord();
        if (extraUserRole) {
          q.destroy();
          return;
        }
        q.noDestroy(false).fetch().subscribe(payload => {
          if (payload.records) {
            return;
          }
          const m = plugin.mutation(payload.dispatchId)
            .switchTo(userRole.__typename);
          const { roles } = plugin.pluginRecord;
          const toCreate = [{
            userId: userRole.userId,
            roleId: roles[roleSlug].__ID
          }];
          // if (roleSlug === ROLES.ADMIN) {
          //   toCreate.push({
          //     userId: userRole.userId,
          //     roleId: roles[ROLES.CONTRIBUTOR].__ID
          //   });
          // }
          m.create(toCreate);
          m.execute();
        });

      });
    }
    recBuilder.addLogic([
      sessRecLogicMgr.getExistingBuilder(MUTATION)
        .useRelative('loginLogoutComplete', 10)
        .setPackageName('@isomorix/attraction-marketing')
        .setModuleName('models/session/logic')
        .setPure(true)
        .setOpMethod(manageCustomLoginOut)
        .setName('manageCustomLoginOut')
        .setDescription('Manages assigning custom roles to users.')
        .done()
    ])
  }
  return modelBuilder;
}

export function prepareSessionModel(modelBuilder) {
  return prepareSessionRecord(modelBuilder);
}













// export const sessionRecordLogicMgr = LogicMgr
//   .initModule('@isomx/attraction-marketing/models/session/record/logic')
//   .addSource(recordLogicMgr);
//
// function manageFormsAndDialogs(action) {
//   const store = action.meta.store;
//   const pkValue = store.get(PKS.SESSION);
//   if (pkValue) return action;
//   const t = action.payload.mutation.recordDataType[store.getPrev(PKS.SESSION)];
//   if (t === DELETE) {
//     const prev = store.value.getPrevState();
//     if (prev && (prev.forms || prev.dialogs)) {
//       const { forms, dialogs } = prev;
//       store.mainStore.subscribe({
//         complete: () => {
//           if (forms) completeStores(forms);
//           if (dialogs) completeStores(dialogs);
//         }
//       });
//     }
//   }
//   return action;
// }
//
// sessionRecordLogicMgr.getBuilder(MUTATION, './mutation')
//   .useRelative('trxPrepare', 10)
//   .setName('manageFormsAndDialogs')
//   .setDescription('Ensures the forms and dialogs props are Objects.')
//   .setPure(true)
//   .add(manageFormsAndDialogs, true);
//
