/*
 * This is the default time (in ms)
 * to use when transitioning between
 * 2D and 3D views.
 */
export const VIEW_MODE_TRANSITION_TIME = 900;

export const SCALE_FAST_TRANSITION_TIME = 50;

export const SCALE_TRANSITION_TIME = 100;

export const SCALE_CLASS_NAME = 'rmd-scale';

export const SCALE_ACTIVE_CLASS_NAME = 'rmd-scale--active';

export const SCALE_FAST_CLASS_NAME = 'rmd-scale-fast';

export const SCALE_FAST_ACTIVE_CLASS_NAME = 'rmd-scale-fast--active';

export const SCALABLE_CARD_CLASS_NAME = 'rmd-scalable-card';

export const SCALABLE_CARD_CONTENT_CLASS_NAME = 'rmd-scalable-card__content';

export const SCALABLE_CARD_FOCAL_ELEM_CLASS_NAME = 'rmd-scalable-card__focal-elem';

export const SCALE_CARD_CLASS_NAME = 'rmd-scale__card';

export const SCALE_CARD_ACTIVE_CLASS_NAME = 'rmd-scale__card--active';

export const SCALE_CARD_TRANSITION_TIME = 125;

export const SCALE_CARD_TRANSITION_TIME_Y_OFFSET = 0.15;

