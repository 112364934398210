import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
} from "@react-md/card";
import { MediaContainer, MediaOverlay } from "@react-md/media";
import { Button } from "@react-md/button";
import { FontIcon, TextIconSpacing } from "@react-md/icon";
import { useUserMeta, useUserRole } from "@isomorix/react";
import { LinearProgress } from "@react-md/progress";
// import { Badge } from "@react-md/badge";
import { ROUTE_SLUGS } from "../../constants";
import { useNoLink, useOnClickRemoveAccess } from "../../hooks";
import { delay } from "rxjs/operators";
// import {Background, Parallax} from "react-parallax";

const metaKeys = [
  'joinCommunity',
  ROUTE_SLUGS.TRAINING_AMF
];


export const WelcomeCards = React.memo(function WelcomeCards(
  {
    session,
    openJoinForm,
    intersectionRef,
  }
) {
  const meta = useUserMeta(metaKeys, true);
  const amfCheckout = useUserMeta('amfCheckout');
  const [ completeKeys, metaByKey ] = useMemo(() => {
    if (!meta) {
      return [ [], amfCheckout ? { amfCheckout } : null ];
    }
    const obj = amfCheckout ? { amfCheckout } : {};
    const complete = [];
    let key;
    for(let pkValue in meta) {
      key = meta[pkValue].key;
      obj[key] = meta[pkValue];
      if (obj[key].value === '1') {
        complete.push(key);
      }
    }
    return [ complete, obj ];
  }, [ meta, amfCheckout ]);
  const noLink = useNoLink();
  return (
    <section ref={intersectionRef} className={'welcome-cards'}>
      <h2 className={'headline'}>
        Welcome to AttractionMarketing.com
      </h2>
      <div
        className={'cards rmd-grid'}
        // gutter={'16px'}
        // padding={'0px 48px 48px 48px'}
        // phoneColumns={1}
        // tabletColumns={3}
        // desktopColumns={3}
      >
        <GetStartedCard
          session={session}
          completeKeys={completeKeys}
          metaByKey={metaByKey}
        />
        <JoinCommunityCard
          complete={completeKeys.indexOf('joinCommunity') > -1}
          session={session}
          meta={metaByKey && metaByKey.joinCommunity}
          openJoinForm={openJoinForm}
          noLink={noLink}
        />
        <Card className={'card'} raisable fullWidth>
          <MediaContainer fullWidth={true}>
            <img
              width={'100%'}
              height={'100%'}
              src={'/static/img/featured-amf-bootcamp-new-300.webp'}
              alt={'Free Online Recruiting Course'}
            />
          </MediaContainer>
          <CardContent>
            <div className={'rmd-card__title card--title'}>
              Free LIVE Series Recruiting Training
            </div>
            <div className={'rmd-card__subtitle rmd-card__subtitle--secondary text'}>
              Free Online Recruiting Course Shows You How to Recruit - Rejection FREE!
            </div>
          </CardContent>
          <CardActions className={'last-action'}>
            <Button
              theme={'secondary'}
              themeType={'flat'}
              onClick={noLink}
            >
              <TextIconSpacing
                icon={<FontIcon>subscriptions</FontIcon>}
              >
                Start Your Course
              </TextIconSpacing>
            </Button>
          </CardActions>
        </Card>
      </div>
    </section>
  );
});

const GetStartedCard = React.memo(function GetStartedCard(
  {
    session,
    completeKeys,
    metaByKey
  }
) {
  const userRole = useUserRole(session);
  const startOnClick = useCallback((e) => {
    if (!session) return;
    session.location.push('/start');
  }, [ session ]);
  const [, forceRender ] = useState(0);
  useEffect(() => {
    const sub = session.subscribeToCache([ 'joinCommunity', 'checkout' ])
      .pipe(delay(10))
      .subscribe(() => forceRender(prev => ++prev));
    return () => sub.unsubscribe();
  }, [ session, forceRender ]);
  const jcForm = session.getForm('joinCommunity');
  useEffect(() => {
    if (!jcForm) return;
    const store = jcForm.get('dialogStore');
    if (!store) return;
    let visibility = store.get('visibility');
    const sub = store.subscribe(sv => {
      if (visibility !== sv.get('visibility')) {
        visibility = sv.get('visibility');
        forceRender(prev => ++prev);
      }
    });
    return () => sub.unsubscribe();
  }, [ jcForm ]);

  let progValue = 0;
  if (completeKeys.indexOf('joinCommunity') > -1) {
    progValue += 1;
  } else if (!jcForm) {
    progValue = userRole ? 0.2 : 0.1;
  } else if (jcForm.get('dialogStore').get('visibility')) {
    progValue += 0.5;
  } else {
    progValue += 0.3;
  }
  if (completeKeys.indexOf(ROUTE_SLUGS.TRAINING_AMF) > -1) {
    progValue += 1;
  } else {
    if (
      metaByKey
      && metaByKey.amfCheckout
      && metaByKey.amfCheckout.value === '1'
    ) {
      progValue += 0.45;
    } else if (userRole) {
      progValue += 0.1;
    }
    if (session.getForm('checkout')) {
      progValue += 0.25;
    }
  }
  return (
    <Card className={'card'} raisable fullWidth>
      <MediaContainer fullWidth={true}>
        {/*<div*/}
        {/*  style={{*/}
        {/*    backgroundImage: `url("/static/img/AM.com-bg-square-300x300.webp")`,*/}
        {/*    backgroundSize: 'auto,cover',*/}
        {/*    backgroundRe*/}
        {/*  }}*/}
        {/*></div>*/}
        <img
          width={'100%'}
          height={'100%'}
          src={'/static/img/AM.com-bg-square-300x300.webp'}
          alt={'Start with Attraction Marketing'}
        />
        <ProgressOverlay
          min={0}
          max={3}
          // value={completeKeys.length || 0.1}
          value={progValue}
        />
        {/*<div style={{*/}
        {/*  position: 'absolute',*/}
        {/*  width: '100%',*/}
        {/*  left: '0',*/}
        {/*  top: '40%',*/}
        {/*  // textAlign: 'center',*/}
        {/*  color: '#fff',*/}
        {/*  fontSize: '1.5rem',*/}
        {/*  backgroundColor: 'rgba(0, 0, 0, 0.5)',*/}
        {/*  padding: '12px 10% 24px 10%'*/}
        {/*}}>*/}
        {/*  <span*/}
        {/*    style={{*/}
        {/*      marginLeft: `${Math.round((completeKeys.length / 3 * 100)) - 3}%`*/}
        {/*    }}*/}
        {/*  >{ Math.round((completeKeys.length / 3 * 100))}%</span>*/}

        {/*  { progress }*/}
        {/*  /!*<Badge*!/*/}
        {/*  /!*  theme={'secondary'}*!/*/}
        {/*  /!*  style={{*!/*/}
        {/*  /!*    left: '33%',*!/*/}
        {/*  /!*    top: '26%',*!/*/}
        {/*  /!*    width: '32px',*!/*/}
        {/*  /!*    height: '32px',*!/*/}
        {/*  /!*    fontSize: '0.75rem',*!/*/}
        {/*  /!*    color: 'var(--rmd-theme-on-secondary)'*!/*/}
        {/*  /!*  }}*!/*/}
        {/*  /!*>33%</Badge>*!/*/}
        {/*</div>*/}

      </MediaContainer>
      <CardContent>
        <div className={'rmd-card__title'}>
          Get Started with Attraction Marketing
        </div>
        <div className={'rmd-card__subtitle rmd-card__subtitle--secondary'}>
          Discover a Powerful NEW WAY of Building Your Team Using Authentic, Automated & Duplicatable Strategies
        </div>
      </CardContent>
      <CardActions>
        <Button
          theme={'secondary'}
          themeType={'flat'}
          onClick={startOnClick}
        >
          <TextIconSpacing
            icon={<FontIcon>start</FontIcon>}
          >
            Start Here
          </TextIconSpacing>
        </Button>
      </CardActions>

    </Card>
  )
})

const JoinCommunityCard = React.memo(function JoinCommunityForm(
  {
    session,
    complete,
    meta,
    openJoinForm,
    noLink
  }
) {
  // console.log('_ts = ', meta && meta['_ts_']);
  // console.log('meta = ', meta);
  // const ts = new Date(meta['_ts_'] * 1000);
  // console.log('date = ', ts.toString());
  const leaveOnClick = useOnClickRemoveAccess('joinCommunity');
  let overlay;
  if (complete) {
    overlay = (
      <MediaOverlay
        style={{ color: '#fff'}}
        position={'absolute-center'}
      >
        <div
          style={{
            display: 'inline',
            verticalAlign: 'middle',
          }}
        >
          <FontIcon style={{
            backgroundColor: '#fff',
            borderRadius: '1.25rem',
            color: 'var(--rmd-theme-success)',
            fontSize: '1.75rem',
          }}>check_circle</FontIcon>
        </div>
        <div
          style={{
            verticalAlign: 'middle',
            display: 'inline',
            marginLeft: '8px',
            fontSize: '1.25rem',
            // paddingTop: '15px'
          }}
        >
          {/*{ ts.toLocaleDateString() } {ts.toLocaleTimeString()}*/}
          Done!
        </div>
      </MediaOverlay>
    )
  }
  return (
    <Card className={`card${complete ? ' card-complete' : ''}`} raisable fullWidth>
      <MediaContainer fullWidth={true}>
        <img
          width={'100%'}
          height={'100%'}
          src={'/static/img/featured-group-phone-300x300.webp'}
          alt={'Join our facebook community'}
        />
        { overlay }
      </MediaContainer>

      <CardContent>
        <div className={'rmd-card__title'}>
          Join Our Private Community of 24,500+
        </div>
        <div className={'rmd-card__subtitle rmd-card__subtitle--secondary'}>
          {
            complete
              ? 'Complete!'
              : '...And Get Our Non-"Salesy" Social Media Posts that ATTRACT High Quality Customers & Team Builders!'
          }
        </div>
      </CardContent>
      <CardActions>
        {
          complete && (
            <Button
              theme={'clear'}
              themeType={'flat'}
              onClick={leaveOnClick}
            >Leave</Button>
          )
        }
        <Button
          theme={'secondary'}
          themeType={'flat'}
          onClick={complete ? noLink : openJoinForm}
        >
          <TextIconSpacing
            icon={<FontIcon>{ complete ? 'groups' : 'group_add' }</FontIcon>}
          >
            { complete ? 'Access Training' : 'Learn More' }
          </TextIconSpacing>
        </Button>
      </CardActions>
    </Card>
  );
})


const ProgressOverlay = (
  {
    min,
    max,
    value,
  }
) => {
  if (value === 0) return null;

  // const percent = Math.round((value / max) * 100);
  const progress = (
    <LinearProgress
      animate={true}
      value={value}
      min={min}
      max={max}
      style={{ marginTop: '0.5rem' }}
      className={'rmd-progress--secondary'}
      aria-label={'Your Progress Getting Started With Attraction Marketing'}
    />
  );
  // return (
  //   <MediaOverlay>
  //     <span
  //       style={{
  //         marginLeft: `${percent - 3}%`
  //       }}
  //     >{ percent}%</span>
  //     { progress }
  //     {/*<span>My Progress</span>*/}
  //   </MediaOverlay>
  // )
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      left: '0',
      top: '40%',
      // textAlign: 'center',
      color: '#fff',
      fontSize: '1.2rem',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: '12px 10% 24px 10%',
    }}>
          {/*<span*/}
          {/*  style={{*/}
          {/*    marginLeft: `${percent - 3}%`*/}
          {/*  }}*/}
          {/*>{ percent}% {userRole.user.firstName}!</span>*/}
      <span
        style={{
          // marginLeft: `${percent - 3}%`,
          textAlign: 'left',
          marginBottom: '1rem'
        }}
      >Your Progress</span>
      { progress }
      {/*<Badge*/}
      {/*  theme={'secondary'}*/}
      {/*  style={{*/}
      {/*    left: '33%',*/}
      {/*    top: '26%',*/}
      {/*    width: '32px',*/}
      {/*    height: '32px',*/}
      {/*    fontSize: '0.75rem',*/}
      {/*    color: 'var(--rmd-theme-on-secondary)'*/}
      {/*  }}*/}
      {/*>33%</Badge>*/}
    </div>
  )
}
