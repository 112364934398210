import React from 'react';
import { CORE_LOGIC } from "../../../constants";
import { createVideoGridLogic } from "../VideoGridLogic";

export const Amf = createVideoGridLogic(
  CORE_LOGIC.TRAINING_AMF,
  {
    headline: 'Attraction Marketing Formula',
  }
)
