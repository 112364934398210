import React from 'react';
import { TextContainer, Typography } from '@react-md/typography';

export function PermissionDenied(
  {
    logic
  }
) {
  return (
    <TextContainer style={{ marginBottom: '40vh'}}>
      <Typography type={'headline-1'}>
        Oops!
      </Typography>
      <Typography type={'body-1'}>
        You do not have permission to access this page.
      </Typography>
    </TextContainer>
  );
}
