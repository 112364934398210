import React, { useCallback } from 'react';
import { useSessionContext } from "@isomorix/react-router";
import { TextIconSpacing } from "@react-md/icon";
import { IMG_BASE_PATH } from "../constants";

export const AppBarLogo = React.memo(function AppBarLogo(
  {
    title = 'Attraction Marketing',
    clickable,
    first
  }
) {
  const session = useSessionContext();
  const logoClick = useCallback(() => {
    session.location.push('/');
  }, [ session ]);
  return (
    <TextIconSpacing
      className={first ? 'main-app-bar-title-logo--first' : undefined}
      icon={(
        <img
          width={'24px'}
          height={'24px'}
          alt={'AttractionMarketing.com'}
          className={'main-app-bar-title-logo'}
          src={`${IMG_BASE_PATH}/fav.png`}
          onClick={clickable ? logoClick : undefined}
        />
      )}
    >
      <span className={'main-app-bar-title-text'}>
        { title }
      </span>
    </TextIconSpacing>
  )
})
