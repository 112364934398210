import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MUTATION } from '@isomorix/core-actions';
import { DOCS_URL_SEARCH as SEARCH } from '@isomorix/core-config';
import { routerLogicMgr } from '../../router';

const builder = routerLogicMgr.getBuilder(MUTATION, './mutation');
function loadLayout3D(action) {
  const { localProps } = action.meta.instance;
  // no local props would mean we're being deleted because
  // routers and routes always have localProps associated with them.
  if (!localProps || localProps.viewMode !== '3D') {
    return action;
  }
  const { meta } = action;
  // remove this logic since it only needs to run once.
  meta.removeLogic(meta.getCurrentLogic(), 'all', 'all');
  if (!localProps.createLayout3DStore) {
    const main = action.meta.mainInstance;
    forkJoin({
      mutation: action.payload.mutation.ofComplete(true),
      module: localProps.loadLayout3DModule()
    }).subscribe(({ module }) => {
      main.setLocalProps({
        createLayout3DStore: module.createLayout3DStore,
        Layout3D: module.Layout3D
      });
    });
  }
  return action;
}

builder.useRelative('trxPrepare', 10)
  .setName('loadLayout3D')
  .setPure(true)
  .setDescription(`Client-only logic that handles loading the Layout3D module when the viewMode is changed to 3D. Once this occurs the first time, this logic will be removed.`)
  .add(loadLayout3D, true);

function updateDocsMatch(action, observer) {
  const logic = action.meta.instance;
  let { localProps, match } = logic;
  // no local props would mean we're being deleted.
  if (!localProps) {
    localProps = action.meta.store.value.getPrev('localProps');
    if (localProps && localProps.docsMutationLogic) {
      return localProps.docsMutationLogic(action, observer);
    }
    return action;
  }
  const searchParams = match && match.searchParams;
  if (searchParams && searchParams[SEARCH.PATHNAME]) {
    const { docsMutationLogic } = localProps;
    if (!docsMutationLogic) {
      return localProps.loadDocsModule(action).pipe(
        mergeMap(module => {
          const localProps = logic.getMutableLocalProps();
          localProps.createDocsStore = module.createPortalStore;
          localProps.docsMutationLogic = module.mutationLogic;
          localProps.docsCss = module.css;
          localProps.docsTemplateCss = module.templateCss;
          localProps.DocsSearchButton = module.SearchButton;
          const docsStore = module.createPortalStore(action);
          localProps.docsStore = docsStore;
          docsStore.value.commit();
          logic.localProps = localProps;
          return localProps.docsMutationLogic(action);
        })
      )
    } else {
      return docsMutationLogic(action, observer);
    }
  } else if (
    localProps.docsStore
    && localProps.docsStore.get('portalVisible')
  ) {
    return localProps.docsMutationLogic(action, observer);
  } else {
    return action;
  }
}
builder.useRelative('trxOptimisticExecute', 10)
  .setName('updateDocsMatch')
  .setDescription(`Client-only logic that manages the docs portal (dialog) based on url search parameters.`)
  .add(updateDocsMatch, true);


if (process.env.NODE_ENV !== 'production') {
  /*
   * Add logic to clean up resources between hot reloads
   * if the entire app has to be reloaded. When that occurs,
   * the previous state will be "deleted" (locally), which
   * we determine by checking whether the record has
   * an ID associated with it. The only time it wouldn't
   * would be when it is being deleted.
   */
  function cleanupLocalResources(action) {
    const logic = action.meta.instance;
    if (logic.__ID) {
      // not being deleted.
      return action;
    }
    // locate the previous Object and clean up the extra resources.
    const localProps = action.meta.store.value.getPrev('localProps');
    if (localProps) {
      if (localProps.layout3DStore) {
        localProps.layout3DStore.complete();
      }
      if (localProps.routesMgr) {
        localProps.routesMgr.destroy();
      }
    }
    return action;
  }

  /*
   * Since we're only providing an offset (+10), it
   * will offset this logic from the last logic to
   * be created, which is the `updateDocsMatch` logic.
   */
  builder.useRelative(10)
    .setName('cleanupLocalResources')
    .setPure(true)
    .add(cleanupLocalResources, true);
}
