import { initBuilder, CoreBuilder } from '../universalEntry';
import { Entry } from '@isomorix/core';
import { asyncScheduler } from 'rxjs';
import { pluginLogicMgr } from './logic';
import { ofChunksReady } from '@isomorix/react-router';
import { defaultCorePluginRecord } from "@isomorix/core-helpers";

const init = (entry) => {
  let builder, sc;
  /*
   * Initializing the plugin using scheduled tasks
   * ensures a smooth user experience
   */
  asyncScheduler.schedule(() => {
    sc = CoreBuilder.createStoreConfig(false);
  }, 0);
  asyncScheduler.schedule(() => {
    defaultCorePluginRecord(sc);
  }, 0);
  asyncScheduler.schedule(() => {
    builder = initBuilder(sc, pluginLogicMgr);
  }, 0);
  asyncScheduler.schedule(() => {
    builder.createStore();
    if (process.env.NODE_ENV === 'production') {
      window.getState = function() {
        return entry.mainStore;
      }
      window.getModel = entry.getModel;
    }
  }, 0);
  asyncScheduler.schedule(() => {
    entry.registerAction(builder.dispatchInit()).subscribe();
  }, 0);
}
/*
 * Using the Entry class to manage this entry
 * simply automates handling hot module replacement.
 */
const entry = new Entry(init, false);
if (module.hot) {
  module.hot.accept([
    '../universalEntry',
    '@isomorix/core',
    '@isomorix/react-router',
    '@isomorix/core-helpers',
    './logic',
  ], entry.init);
  if (typeof window !== 'undefined') {
    /*
     * Optionally add methods to window
     * to make it easy to perform various
     * actions via the dev tools console.
     *
     * For example, adding `getModel()`
     * means queries and mutations can
     * be performed directly from the
     * console.
     */
    window.reloadApp = entry.init;
    window.getModel = entry.getModel;
  }
}
ofChunksReady().subscribe(entry.init);
