import React, { createElement, useEffect, useState } from 'react';
import {
  LayoutContainer as RmdLayoutContainer,
  useUndoRedo
} from '@isomorix/react-md-layout';
import { isAppBuilderPath } from "../helpers";
import { AI_CHAT_DIALOG_NAME } from "../constants";
import { LinearProgress } from "@react-md/progress";

const useAiChatDialog = (location, AiChatDialog) => {
  if (AiChatDialog) {
    return <AiChatDialog />
  } else if (
    location
    && location.searchParams[AI_CHAT_DIALOG_NAME]
  ) {
    return (
      <LinearProgress
        className={'rmd-progress--secondary'}
        style={{
          position: 'fixed',
          top: 49,
          zIndex: 30
        }}
      />
    )
  }
  return null;
}
/*
 * Try/catch is used in case the app is
 * started in node outside a bundle. It
 * won't prevent the server from starting.
 */
let css;
let LayoutContainer;
if (process.env.BROWSER) {
  LayoutContainer = function LayoutContainer(props) {
    const { logic, location } = props;
    const {
      localProps: { appBuilderModule, AiChatDialog }
    } = logic;
    const aiChat = useAiChatDialog(location, AiChatDialog);
    const [ plugin, setPlugin ] = useState(logic.getPlugin());
    useEffect(() => {
      let isAppBuilder;
      const sub = location.subscribe(({ pathname }) => {
        if (isAppBuilderPath(pathname)) {
          if (!isAppBuilder) {
            setTimeout(() => setPlugin(null), 1500);
            isAppBuilder = true;
          }
        } else if (isAppBuilder) {
          setTimeout(() => setPlugin(logic.getPlugin()), 1500);
          isAppBuilder = false;
        }
      });
      return () => sub.unsubscribe();
    }, [ location, logic ]);
    useUndoRedo(plugin);

    if (appBuilderModule) {
      const { DndProvider } = appBuilderModule;
      return (
        <DndProvider backend={appBuilderModule.HTML5Backend}>
          { createElement(RmdLayoutContainer, props) }
          { aiChat }
        </DndProvider>
      )
    } else if (aiChat) {
      return (
        <>
          { createElement(RmdLayoutContainer, props) }
          { aiChat }
        </>
      )
    } else {
      return createElement(RmdLayoutContainer, props);
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    let clientCss, useCss;
    try {
      clientCss = require('./styles.scss');
      ({ useCss } = require('@isomorix/react-router'));
    } catch(e) {}
    const LayoutContainerProduction = LayoutContainer;
    LayoutContainer = function LayoutContainer(props) {
      useCss(clientCss);
      return createElement(LayoutContainerProduction, { ...props });
    }
  }
} else {
  try {
    css = require('./styles.scss');
  } catch(e) {}
  LayoutContainer = function LayoutContainer({ ...props }) {
    const {
      localProps: { AiChatDialog }
    } = props.logic;
    const aiChat = useAiChatDialog(props.location, AiChatDialog);
    return !aiChat
      ? createElement(RmdLayoutContainer, props)
      : (
        <>
          { createElement(RmdLayoutContainer, props) }
          { aiChat }
        </>
      )
  }
}
// export function LayoutContainer(props) {
//   useCss(css);
//   /*
//    * Adds listeners to the document for
//    * ctrl + z and ctrl + shift + z for
//    * undo/redo.
//    *
//    * In development environment it also
//    * adds window.timeTravel(deltaOrTimestamp, orBest) and
//    * window.removeTimeTravelTs(timestamp) that
//    * can be called from the console and
//    * then forwards the call to the Plugin instance.
//    */
//   useUndoRedo(props.logic.getPlugin());
//   return <RmdLayoutContainer { ...props } />
// }

export { LayoutContainer, css };
