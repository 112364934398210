import { Training } from "./Training";
import { Amf } from './amf';
import { ReelsForReal } from "./reelsForReal";
import { SocialRetailBlueprint } from "./socialRetailBlueprint";
const moduleName = './routes/training';

export function trainingRoute(pluginBuilder, coreLogicRoutes) {
  coreLogicRoutes
    .addRoute(Training, moduleName)
    .addRoute(Amf, `${moduleName}/amf`)
    .addRoute(ReelsForReal, `${moduleName}/reelsForReal`)
    .addRoute(SocialRetailBlueprint, `${moduleName}/socialRetailBlueprint`);
}
