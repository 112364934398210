import React, { useEffect, useRef } from 'react';
import {Button} from "@react-md/button";
import { useAmfLetterLinks } from "../../hooks";
// import {Background, Parallax} from 'react-parallax';

export const Main = React.memo(function Main(
  {
    session,
    setElevation
  }
) {
  const mainRef = useRef();
  const {
    openLetter,
    openCheckout,
    openTraining,
    checkout,
    user,
    hasCheckout,
    hasAccess
  } = useAmfLetterLinks();
  useEffect(() => {
    if (!mainRef.current || !setElevation) return;
    const obs = new IntersectionObserver(([ { isIntersecting } ]) => {
      setElevation(!isIntersecting);
    });
    obs.observe(mainRef.current);
    return () => obs.disconnect();
  }, [ setElevation, mainRef.current ]);
  let preHeadline = `The Secret's Finally Out...`,
    subHeadline = `Learn all about the Legendary ‘Leader’s Playbook’ Most Online Top Earners are Using to Passively Attract a Massive Influx of New Prospects, Customers & Team Members via Social Media on Auto-pilot!`,
    secondaryButtonText,
    secondaryButtonOnClick,
    primaryButtonText,
    primaryButtonOnClick;
  if (hasAccess) {
    preHeadline = `Continue Your Training${user ? `, ${user.firstName}` : ''}!`;
    primaryButtonText = 'Training Area >>>';
    primaryButtonOnClick = openTraining;
    subHeadline = undefined;
  } else if (checkout || hasCheckout) {
    if (checkout) {
      secondaryButtonText = 'Read More';
      secondaryButtonOnClick = openLetter;
      primaryButtonOnClick = openCheckout;
    } else {
      primaryButtonOnClick = openLetter;
    }
    primaryButtonText = `Finish Setup${user ? `, ${user.firstName}` : ''} >>>`;
  } else {
    primaryButtonText = 'Learn More >>>';
    primaryButtonOnClick = openLetter;
  }

  return (
    // <Parallax
    //   // bgImage={'/static/img/am-magnet-bg.webp'}
    //   parent={typeof document === 'undefined'
    //     ? undefined
    //     : document.getElementById('rmd-layout-main-content-scrollable')
    //   }
    //   strength={800}
    // >
    //   <Background>
    //     <img src={'/static/img/am-magnet-bg.webp'} />
    //   </Background>
    <section ref={mainRef} className={'main'}>
      <div className={'main-container'}>
        <div className={'main-product-image'}>
          <div className={"course-bundle"}>
            <img
              className={'home-main-course-bundle-image'}
              alt={'Attraction Marketing Formula course'}
              src={'/static/img/AMF-course-bundle-27_500.webp'}/>
          </div>
          <div className={'testimonial'}>
            <p className={'testimonial--text'}>
              "I Rank Advanced 5 Times in 8 Months and My Team Has Grown to 4,178... Built 100% on Social Media"
            </p>
            <p className={'testimonial--name'}>
              -- Helen Martin, New Top Earner
            </p>
            <div className={'testimonial--stars'}>
              <img
                width={'150px'}
                height={'30px'}
                alt={'Attraction Marketing Formula course review 5-stars'}
                src={'/static/img/5-stars-9.webp'} />
            </div>
          </div>
        </div>
        <div className={'main-product-details'}>
          <span className={'pre-headline'}>{ preHeadline }</span>
          <h1 className={'headline'}>How Average Networkers are Passively Attracting Prospects & Growing Teams of 1000, 2000 or Even 3000+ People in 12 Months or Less</h1>
          { subHeadline && <h2 className={'sub-headline'}>{ subHeadline }</h2> }
          {
            secondaryButtonOnClick && (
              <div className={'learn-more-button-container'}>
                <Button
                  className={'read-more-button'}
                  theme={'clear'}
                  themeType={'flat'}
                  aria-label={'Read More'}
                  onClick={secondaryButtonOnClick}
                >
                  { secondaryButtonText }
                </Button>
              </div>
            )
          }
          <div className={'learn-more-button-container'}>
            <Button
              className={'learn-more-button'}
              theme={'secondary'}
              themeType={'contained'}
              onClick={primaryButtonOnClick}
            >
              { primaryButtonText }
            </Button>
          </div>
        </div>
      </div>
    </section>
    // </Parallax>
  )
});
