import { GraphqlPlayground } from './playground';
import { GraphqlVoyager } from './voyager';
import { ApiDetails } from './ApiDetails';

const moduleName = `./routes/api_details`;

export function apiDetailsRoute(pluginBuilder, coreLogicRoutes) {
  coreLogicRoutes
    .addRoute(ApiDetails, moduleName)
    .addRoute(GraphqlPlayground, `${moduleName}/graphqlPlayground`)
    .addRoute(GraphqlVoyager, `${moduleName}/graphqlVoyager`)
}
