import { uniqueId } from "@isomorix/utils";
import { createRef } from "react";
import { Value } from "./Value";
import { createStore } from '@isomorix/state';
import {
  SCALE_TRANSITION_TIME,
  SCALE_FAST_TRANSITION_TIME,
  SCALE_CLASS_NAME,
  SCALE_ACTIVE_CLASS_NAME,
  SCALE_FAST_CLASS_NAME,
  SCALE_FAST_ACTIVE_CLASS_NAME
} from '../../constants';

export function scaleDialogOnRequestClose(
  store,
  eventOrReplace,
  to
) {
  const sv = store.value;
  const s = sv && sv.getState();
  if (!s || !s.visibility) return;
  const { location } = s;
  const search = location && sv.getCloseDialogSearchParams();
  sv.setVisibility(false);
  sv.commitAndNotify();
  if (!to && !search) return;

  if (!to) {
    eventOrReplace === true
      ? location.replaceSearch(search)
      : location.pushSearch(search);
    return;
  }
  if (search) {
    if (to.searchParams) {
      to.searchParams = {
        ...search,
        ...to.searchParams
      };
    } else {
      to.searchParams = search;
    }
  }
  if (eventOrReplace === true) {
    to.pathname
      ? location.replace(to, to.state)
      : location.replaceSearch(to.searchParams, to.state);
  } else {
    to.pathname
      ? location.push(to, to.state)
      : location.pushSearch(to.searchParams, to.state);
  }
}

export function createScaleDialogStore(options, setupOptions) {
  if (!options) options = {};
  const curr = options.state;
  const isFullPage = setupOptions && setupOptions.fullPage || false;
  let location, visibility = false, appSize;
  if (curr) {
    if (!(location = curr.location) && curr.session) {
      location = curr.session.location;
    }
    visibility = curr.visibility || false;
    appSize = curr.appSize;
  }
  let state = {
    dialogId: curr && curr.dialogId || uniqueId(),
    portal: false,
    appear: true,
    /*
     * An optional Object whose keys are the
     * searchParams and whose values are the
     * prop to reference in state when opening/closing
     * the dialog.
     *
     * It is used by openDialog() and
     * closeDialog() + onRequestClose()
     */
    extraSearchParams: undefined,
    Content: undefined,
    /*
     * Used in the component's useEffect()
     * to set a timeout to update transitions.
     * It also uses this value to determine
     * when to call sv.updateTransition().
     */
    updateTransitionTimeout: 0,
    dialogStyle: undefined,
    isTransitioning: false,
    offsetX: 0,
    offsetY: 0,
    appSize: undefined,
    dialogRef: createRef(),
    visibilitySearchParam: undefined,
    offsetXSearchParam: undefined,
    offsetYSearchParam: undefined,
    locationSub: undefined,
    type: isFullPage ? 'full-page' : 'centered',
    phoneType: 'full-page',
    tabletType: isFullPage ? 'full-page' : 'centered',
    desktopType: isFullPage ? 'full-page' : 'centered',
    location: undefined,
    /*
     * Function that is called by the subscription
     * to the location when the location changes.
     * Useful if additional searchParams are
     * being used so that an extra subscription
     * to the location doesn't have to occur.
     *
     * For example, the aiChat implementation uses
     * this hook to handle updating fullscreen.
     */
    onLocationChange: undefined,
    visibility: false,
    viewCount: 0
  };
  if (setupOptions && setupOptions.baseSearchParam) {
    const b = setupOptions.baseSearchParam;
    state.visibilitySearchParam = `${b}`;
    state.offsetXSearchParam = `${b}X`;
    state.offsetYSearchParam = `${b}Y`;
  }
  if (curr) {
    for(let key in curr) {
      state[key] = curr[key];
    }
    if (state.phoneType === 'fullPage') {
      state.phoneType = 'full-page';
    }
    if (state.tabletType === 'fullPage') {
      state.tabletType = 'full-page';
    }
    if (state.desktopType === 'fullPage') {
      state.desktopType = 'full-page';
    }
    // Will be set at the end. They check
    // if the values are the same for performance,
    // so make sure they're unset initially.
    state.location = undefined;
    state.visibility = false;
  }
  if (!state.scaleCn) {
    if (setupOptions && setupOptions.scaleFast) {
      state.scaleCn = SCALE_FAST_CLASS_NAME;
      state.scaleActiveCn = SCALE_FAST_ACTIVE_CLASS_NAME;
      state.transitionTime = SCALE_FAST_TRANSITION_TIME;
    } else {
      state.scaleCn = SCALE_CLASS_NAME;
      state.scaleActiveCn = SCALE_ACTIVE_CLASS_NAME;
      state.transitionTime = SCALE_TRANSITION_TIME;
    }
  }

  if (state.dialogCn) {
    state.dialogCn = {
      [state.scaleCn]: false,
      [state.scaleActiveCn]: false,
      ...state.dialogCn
    };
  } else {
    state.dialogCn = {
      [state.scaleCn]: false,
      [state.scaleActiveCn]: false,
    };
  }
  if (!state.onRequestClose) {
    state.onRequestClose = (eventOrReplace, to) => {
      scaleDialogOnRequestClose(store, eventOrReplace, to);
    }
  }
  options.state = state;
  if (!options.Value) {
    options.Value = Value;
  }
  const store = createStore(options);
  if (location) {
    store.value.setLocation(location);
  }
  if (visibility) {
    store.value.setVisibility(visibility);
  }
  if (appSize) {
    store.value.updateType(appSize, true);
  } else if (store.value.pState) {
    store.value.commit();
  }
  return store;
}
