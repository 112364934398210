import {
  AI_AVATAR,
  AI_DATA_MODEL, AI_INTERACTION_STATUS,
  ENUM_TYPES,
} from "../constants";
import { snakeCaseToWords } from "@isomorix/utils";

export function defineCustomEnums(types) {
  const avatarValues = [];
  for(let key in AI_AVATAR) {
    avatarValues.push({
      name: AI_AVATAR[key],
      value: snakeCaseToWords(AI_AVATAR[key])
    })
  }
  const avatarDef = {
    name: ENUM_TYPES.AI_AVATAR,
    values: avatarValues
  };
  const aiDataModel = {
    name: ENUM_TYPES.AI_DATA_MODEL,
    values: Object.values(AI_DATA_MODEL)
  }
  const interactionStatus = {
    name: ENUM_TYPES.AI_INTERACTION_STATUS,
    values: Object.values(AI_INTERACTION_STATUS)
  }
  if (!process.env.BROWSER) {
    avatarDef.description = `The ${ENUM_TYPES.AI_AVATAR} represents the avatars (a.k.a. personalities) that the Ai can assume.`;
    avatarDef.JSDOC_TYPE = 'string';
    aiDataModel.description = `The ${ENUM_TYPES.AI_DATA_MODEL} represents the data model that the Ai should consider when answering a request.`;
    aiDataModel.JSDOC_TYPE = 'string';
    interactionStatus.description = `The ${ENUM_TYPES.AI_INTERACTION_STATUS} represents the status of an Ai interaction. It is used to coordinate submitting and processing the interaction.`;
    interactionStatus.JSDOC_TYPE = 'string';
  }
  types.Enum.define(avatarDef);
  types.Enum.define(aiDataModel);
  types.Enum.define(interactionStatus);
}
