import { CORE_LOGIC_ACTIONS as ACTIONS } from '@isomorix/core';
import { routerLogicMgr as logicMgr } from './logicMgr';
import { VIEW_MODE_TRANSITION_TIME } from '../../constants';
import { Layout } from '../Layout';


const builder = logicMgr.getBuilder(ACTIONS.INIT, './init');

function initExtraLocalProps(action) {
  const logic = action.meta.instance;
  const { localProps } = logic;
  localProps.viewMode = '2D';
  localProps.render2D = true;
  localProps.render3D = false;
  localProps.viewModeTransitionTime = VIEW_MODE_TRANSITION_TIME;
  localProps.Layout = Layout;
  return action;
}

builder.useRelative('initLocalProps', 10)
  .setName('initExtraLocalProps')
  .setPure(true)
  .add(initExtraLocalProps, true);



